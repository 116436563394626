@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

#about {
  .c-mv {
    @include media(sm) {
      padding: 3rem 0;
      &__tit{
        margin-top: 0;
        .en &{
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  .p-sec01 {
    position: relative;
    padding: 5rem 0 6rem;
    @include media(sm) {
      padding: 4rem 0 5rem;
      background: url(../../images/about_sec01_bg_sp@2x.png) no-repeat;
      background-position: top;
      background-size: 100%;
    }
    &__bg {
      position: absolute;
      z-index: -1;
      right: 80px;
      bottom: 0;
      @include media(sm) {
        display: none;
      }
    }
    &-box {
      display: flex;
      @include media(sm) {
        flex-direction: column-reverse
      }
      &__textArea {
        @include media(sm) {
          margin-top: 30px;
        }
        .tit {
          @include fz(36, 45, 400);
          color: $tit;
          margin-bottom: 30px;
          .jp &{
            font-weight: 700;
            line-height: 1.5;
          }
          @include media(sm) {
            font-size: 25px;
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 20px;
            margin-bottom: 20px;
            .jp &{
              line-height: 1.25;
              margin-bottom: 25px;
            }
          }
        }
      }
      &__imageArea {
        flex-shrink: 0;
        margin-left: 65px;
        .jp &{
          margin-left: 68px;
        }
        @include media(sm) {
          margin-left: 0;
          text-align: center;
          .jp &{
            margin-left: 0;
          }
          img {
            width: 350px;
          }
        }
      }
    }
  }
  .sectionWrap0203 {
    background: url(../../images/sectionWrapBg.jpg);
    background-size: cover;
  }
  .p-sec02 {
    padding-top: 6rem;
    @include media(sm) {
      padding-top: 5rem;
    }
    &__tit {
      text-align: center;
      @include fz(36, 45, 400);
      color: $tit;
      margin-bottom: 66px;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 25px;
        margin-bottom: 4rem;
        .jp &{
          letter-spacing: -0.09px;
        }
      }
      .num {
        @include fz(55,55,400);
        .jp &{
          font-size: 66px;
          font-weight: 700;
        }
        @include media(sm) {
          font-size: 32px;
          .jp &{
            font-size: 44px;
          }
        }
      }
    }
    &-box {
      display: flex;
      justify-content: center;
      gap: 30px;
      @include media(sm) {
        flex-direction: column;
        gap: 2rem;
      }
      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .imageArea{
          display: flex;
          justify-content: center;
          position: relative;
          .txt{
            position: absolute;
            top: 24%;
            text-align: center;
            color: $tit;
            font-size: 32px;
            font-weight: 700;
            left: 50%;
            transform: translateX(-50%);
            .jp &{
              top: 22%;
              left: 51%;
            }
            @include media(sm) {
              font-size: 26px;
              top: 22%;
              .jp &{
                top: 27%;
              }
            }
            small{
              font-size: 18px;
              @include media(sm) {
                display: block;
                .en &{
                  font-size: 16px;
                }
              }
            }
            span{
              font-size: 57px;
              .jp &{
                font-size: 58px;
              }
              @include media(sm) {
                font-size: 42px;
                line-height: 0.85;
                .jp &{
                  font-size: 41px;
                }
              }
            }
          }
          .image {
            margin-bottom: 2rem;
            width: 55%;
            @include media(sm) {
              width: 75%;
            }
            img {
              @include media(sm) {
                width: 217px;
              }
            }
          }
        }
        &:nth-child(2){
          .imageArea{
            padding-top: 9.8rem;
            .txt{
              top: -5%;
              .en &{
                top: -1%;
                font-size: 21px;
              }
              @include media(sm) {
                top: 11%;
                width: 70%;
                .en &{
                  top: 11%;
                }
              }
              small{
                font-size: 16px;
                .en &{
                  font-size: 18px;
                  @include media(sm) {
                    font-size: 15px;
                  }
                }
              }
              span{
                font-size: 70px;
                @include media(sm) {
                  font-size: 48px;
                  line-height: 0.85;
                }
                .jp &{
                  display: inline-block;
                  font-size: 60px;
                  margin-right: 3px;
                  @include media(sm) {
                    font-size: 41px;
                    margin-top: 4px;
                  }
                }
              }
            }
            .image{
              width: 62%;
              @include media(sm) {
                width: 85%;
              }
            }
          }
        }
        .caution {
          @include fz(14, 19.6, 400);
          max-width: 390px;
          color: gray;
          text-indent: -0.6rem;
          padding-left: 0.6rem;
          .jp &{
            font-weight: 700;
            text-indent: -1.4rem;
            padding-left: 1.4rem;
          }
          @include media(sm) {
            font-size: 12px;
            max-width: none;
            .jp &{
              font-feature-settings: "palt";
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
    &-logoArea {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 7rem 10%;
      margin: 7rem auto 0;
      width: 1100px;
      &__item{
        img{
          width: auto;
          height: 40px;
          &.size-l{
            height: 55px;
          }
          &.size-m{
            height: 35px;
          }
          &.size-s{
            height: 30px;
          }
        }
      }
      // display: grid;
      // grid-template-columns: repeat(5,1fr);
      // margin-top: 7rem; //margin-top: 130px;
      // column-gap: 47px;
      // row-gap: 30px;
      @include media(sm) {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        margin-top: 70px;
        column-gap: 3rem;
        row-gap: 4rem;
        padding: 0 3rem;
        width: unset;
        &__item{
          text-align: center;
        }
      }
      // &.dummy{
      //   display: block;
      //   @include media(sm) {
      //     margin-top: 3rem;
      //     padding: 0;
      //   }
      // }
    }
  }
  .p-sec03 {
    background-size: cover;
    padding: 10rem 0 5rem;
    @include media(sm) {
      padding: 6rem 0 5rem;
    }
    &__tit {
      color: $tit;
      @include fz(36, 45, 400);
      text-align: center;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 25px;
      }
    }
    &__text {
      text-align: center;
      color: $tit;
      @include fz(30, 42, 400);
      margin-top: 2.5rem;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 20px;
        margin-top: 0.5rem;
      }
    }
    &__image {
      text-align: center;
      margin-top: 60px;
      @include media(sm) {
        margin-top: 4rem;
        margin-left: -20px;
        margin-right: -20px;
      }
    }
  }
  .p-sec04 {
    padding: 6rem 0;
    @include media(sm) {
      padding: 5rem 0 4rem;
    }
    &__tit {
      text-align: center;
      @include fz(36, 45, 400);
      color: $tit;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 25px;
      }
    }
    &__text {
      color: $tit;
      @include fz(20, 32, 400);
      text-align: center;
      margin-top: 3rem;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 15px;
        margin-top: 1rem;
      }
    }
    &-contents {
      margin-top: 7rem;
      @include media(sm) {
        margin-top: 4rem;
      }
      &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        @include media(sm) {
          flex-direction: column;
        }
        + .p-sec04-contents__box {
          margin-top: 2rem;
          @include media(sm) {
            margin-top: 4rem;
          }
        }
        &:nth-child(3){
          margin-top: 0;
        }
        &:nth-child(even) {
          flex-direction: row-reverse;
          @include media(sm) {
            flex-direction: column;
          }
          .imageArea {
            margin-right: 0;
            margin-left: 88px;
            @include media(sm) {
              margin: 0 auto;
            }
          }
        }
        .imageArea {
          flex-shrink: 0;
          margin-right: 98px;
          @include media(sm) {
            text-align: center;
            width: 75%;
            margin: 0 auto;
          }
        }
        .textArea {
          max-width: 501px;
          @include media(sm) {
            margin-top: 1.5rem;
          }
          .tit {
            @include fz(24, 30, 400);
            margin-bottom: 3rem;
            .jp &{
              font-weight: 700;
            }
            @include media(sm) {
              font-size: 20px;
              margin-bottom: 1.5rem;
            }
          }
          .c-text16{
            @include media(sm) {
              .jp &{
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
    &__btnArea {
      display: flex;
      justify-content: center;
      margin-top: 78px;
      @include media(sm) {
        margin-top: 50px;
      }
    }
  }
}