@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#contact, #download, #thanks, #brochure {
  .p-sec01 {
    padding: 14rem 0 8rem;
    @include media(sm) {
      padding: 3rem 0 5rem;
    }
    .c-inner{
      max-width: 1170px;
    }
    &-flex{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include media(sm) {
        display: block;
      }
    }
    &-preArea{
      width: 50%;
      padding-left: 20px;
      @include media(sm) {
        width: unset;
        padding-left: 0;
      }
      &__tit {
        @include fz(43, 55.9, 700);
        margin-bottom: 3rem;
        color: #004E98;
        @include media(sm) {
          font-size: 33px;
          text-align: center;
        }
        .jp &{
          font-size: 35px;
          font-feature-settings: "palt";
          letter-spacing: 1px;
          line-height: 1.4;
          @include media(sm) {
            font-size: 32px;
          }
        }
      }
      &__text{
        @include fz(19, 30.4, 400);
        color: #004E98;
        width: 80%;
        text-align: left;
        .jp &{
          @include fz(18, 28.8, 700);
          width: unset;
        }
        @include media(sm) {
          width: 90%;
          text-align: center;
          margin: 2rem auto 0;
          font-feature-settings: "palt";
          letter-spacing: 0.7px;
        }
      }
      &__img{
        margin-top: 5rem;
        margin-left: -33px;
        @include media(sm) {
          margin-left: 0;
          text-align: center;
        }
        img{
          width: 82%;
        }
      }
    }
    &-formArea {
      width: 50%;
      background: #fff;
      padding: 5rem;
      border-radius: 10px;
      box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.1);
      @include media(sm) {
        padding: 3rem;
        margin-top: 50px;
        width: unset;
      }
    }
  }
}
#thanks,#brochure{
  .p-sec01 {
    &-message{
      text-align: center;
      &-ttl{
        @include fz(40, 52, 400);
        color: $tblue;
        margin-bottom: 30px;
        @include media(sm) {
          font-size: 30px;
          .jp &{
            font-size: 28px;
          }
        }
      }
      &-txt{
        margin-bottom: 65px;
        line-height: 3;
        @include media(sm) {
          margin-bottom: 50px;
          line-height: 1.8;
          font-size: 15px;
          .jp &{
            font-size: 14px;
            line-height: 2;
          }
        }
      }
      &-sns{
        font-weight: 400;
        line-height: 1.6;
        @include media(sm) {
          font-size: 14px;
        }
        a{
          color: $tblue;
        }
      }
      &__btnArea{
        display: flex;
        justify-content: center;
        margin-top: 11rem;
        @include media(sm) {
          margin-top: 7rem;
        }
      }
      &-contact{
        @include fz(13, 19.5, 400);
        margin-top: 5rem;
        a{
          color: $tblue;
        }
      }
    }
  }
}
#brochure{
  .p-sec01 {
    &-message{
      &-ttl{
        .jp &{
          font-weight: 700;
        }
      }
    }
  }
}
#cat-contact,#cat-download,#cat-thanks,#cat-brochure{
  & .l-ftr{
    background-color: #fff;
  }
}