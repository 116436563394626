@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#cases_detail {
  .c-mv__tit{
    font-size: 36px;
    @include media(sm) {
      font-size: 25px;
    }
  }
  .p-sec01 {
    padding: 8rem 0;
    @include media(sm) {
      padding: 6rem 0 5rem;
    }
    .c-inner{
      position: relative;
    }
    &__tit {
      color: $tit;
      @include fz(28, 44.8, 400);
      text-align: left;
      margin-bottom: 6rem;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 22px;
        margin-bottom: 3rem;
        .jp &{
          font-feature-settings: "palt";
        }
      }
      &::before {
        content: '';
        position: absolute;
        width: 40px;
        height: 28px;
        background: url(../../images/dquo_start@2x.svg) no-repeat;
        top: -40px;
        left: 0;
        @include media(sm) {
          background-size: 35px;
          top: -30px;
        }
      }
    }
    &-contents {
      &__mv {
        img {
          width: 100%;
        }
      }
      &__business{
        margin-top: 5rem;
        line-height: 1.8;
        .jp &{
          font-weight: 700;
        }
        @include media(sm) {
          margin-top: 4rem;
          font-size: 14px;
        }
      }
      &__box {
        margin-top: 8rem;
        @include media(sm) {
          margin-top: 2rem;
        }
        .boxItem {
          display: flex;
          align-items: center !important;
          gap: 40px;
          padding: 0 35px;
          &.wp-block-columns{
            margin-bottom: 0;
          }
          @include media(sm) {
            display: block;
            padding: 0;
          }
          + .boxItem{
            margin-top: 4rem;
            @include media(sm) {
              margin-top: 2rem;
            }
          }
          .tit {
            color: $tit;
            @include fz(20, 25, 400);
            width: 18rem;
            .jp &{
              font-size: 24px;
              font-weight: 700;
              width: 13rem;
            }
            @include media(sm) {
              width: unset;
              margin-bottom: 0.5rem;
              .jp &{
                font-size: 18px;
                width: unset;
              }
            }
          }
          .list {
            .listItem {
              position: relative;
              @include fz(16,24,400);
              padding-left: 25px;
              .jp &{
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 14px;
              }
              + .listItem {
                margin-top: 12px;
                @include media(sm) {
                  margin-top: 5px;
                }
              }
              &:before {
                position: absolute;
                top: 10px;
                left: 0;
                background-color: #D70C18;
                width: 15px;
                height: 2px;
                content: '';
              }
            }
          }
        }
      }
      &__intro {
        display: flex;
        margin-top: 10rem;
        margin-bottom: 0;
        @include media(sm) {
          margin-top: 6rem;
          flex-direction: column;
          gap: 30px;
        }
        .textArea {
          width: 50%;
          @include media(sm) {
            width: 100%;
          }
          .tit {
            @include fz(24, 36, 400);
            margin-bottom: 40px;
            .jp &{
              line-height: 1.4;
              font-weight: 700;
            }
            @include media(sm) {
              font-size: 20px;
              margin-bottom: 10px;
            }
          }
          .text {
            @include fz(17, 28.9, 400);
            .jp &{
              font-size: 16px;
            }
            @include media(sm) {
              font-size: 15px;
              .jp &{
                font-size: 14px;
              }
            }
            &.red {
              color: #D70C18;
              font-weight: bold;
            }
          }
        }
        .imageArea{
          width: 50%;
          @include media(sm) {
            width: 100%;
          }
          img{
            aspect-ratio: 550/390;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      &__callout{
        padding-top: 10rem;
        margin-bottom: -2rem;
        @include media(sm) {
          padding-top: 8rem;
          margin-bottom: -1rem;
        }
        h2{
          position: relative;
          color: $tit;
          @include fz(28, 44.8, 400);
          .jp &{
            font-weight: 700;
          }
          @include media(sm) {
            font-size: 22px;
            line-height: 1.4;
          }
          &::before{
            content: '';
            position: absolute;
            width: 40px;
            height: 28px;
            background: url(../../images/dquo_start@2x.svg) no-repeat;
            top: -40px;
            left: 0;
            @include media(sm) {
              background-size: 35px;
              top: -30px;
            }
          }
        }
        .caption{
          @include fz(16, 27.2, 400);
          margin-top: 15px;
          @include media(sm) {
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }
      &__interview{
        margin-top: 8rem;
        @include media(sm) {
          margin-top: 4rem;
        }
        h3{
          @include fz(20, 28, 400);
          color: $tit;
          margin-bottom: 15px;
          .jp &{
            font-weight: 700;
          }
          @include media(sm) {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        p{
          @include fz(17, 34, 400);
          .jp &{
            font-size: 16px;
          }
          @include media(sm) {
            font-size: 15px;
            .jp &{
              font-size: 14px;
            }
          }
          + p{
            margin-top: 2rem;
          }
        }
      }
      &__imageBox{
        margin-top: 8rem;
        @include media(sm) {
          margin-top: 4rem;
        }
        .imageItem{
          &.wp-block-columns{
            margin-bottom: 0;
          }
          + .imageItem{
            @include media(sm) {
              margin-top: 40px;
            }
          }
        }
        p{
          @include fz(14, 22.4, 400);
          margin-top: 15px;
          text-align: center;
          @include media(sm) {
            margin-top: 7px;
            font-size: 12px;
            text-align: left;
          }
        }
        &.item1{
          &.wp-block-columns{
            margin-bottom: 0;
          }
          img{
            width: 100%;
          }
        }
        &.item2{
          .imageItem{
            display: flex;
            gap: 15px;
          }
        }
        &.item3{
          display: flex;
          gap: 15px;
          &.wp-block-columns{
            margin-bottom: 0;
          }
          @include media(sm) {
            flex-direction: column-reverse;
          }
          .imageItem{
            @include media(sm) {
              + .imageItem{
                margin-top: 0;
              }
            }
            &.left{
              max-width: 750px;
            }
            &.right{
              .image{
                &:first-child{
                  margin-bottom: 15px;
                  margin-top: 70px;
                  .en &{
                    margin-top: 30px;
                  }
                  @include media(sm) {
                    margin-top: 0;
                    .en &{
                      margin-top: 0;
                    }
                  }
                }
                img {
                  .en &{
                    aspect-ratio: 360/193;
                    object-fit: cover;
                    object-position: 0 0;
                  }
                }
              }
            }
          }
          &.right-big{
            @include media(sm) {
              flex-direction: column;
            }
          }
        }
      }
    }
    &__caption{
      font-size: 13px;
      padding-top: 2rem;
      line-height: 1.5;
    }
    &__snsArea {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;
      gap: 21px;
      @include media(sm) {
        margin-top: 4rem;
      }
    }
    &__btnArea {
      display: flex;
      justify-content: center;
      margin-top: 78px;
      @include media(sm) {
        margin-top: 50px;
      }
    }
  }
}