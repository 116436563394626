@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

.l-ftr {
  background-color: #ECF9FD;
  padding: 53px 0 27px;
  @include media(sm) {
    padding: 40px 0 25px;
  }
  #cat-seminar &{
    background-color: #fff;
  }
  &-box {
    &__up {
      display: flex;
      justify-content: space-between;
      @include media(sm) {
        display: block;
      }
      .logoArea {
        .logo {
          img {
            height: 46px;
            width: auto;
            @include media(sm) {
              height: 39px;
            }
          }
        }
        .infoText {
          @include fz(16, 25.6, 400);
          color: $dblue;
          margin-top: 23px;
          .jp &{
            font-weight: 700;
          }
          @include media(sm) {
            margin-top: 15px;
            font-size: 14px;
            line-height: 1.4;
            .jp &{
              line-height: 1.6;
            }
          }
          .mail {
            display: inline-block;
            margin-top: 10px;
            .mailLink {
              display: flex;
              align-items: center;
              @include fz(18, 21, 400);
              color: $dblue;
              .jp &{
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 17px;
              }
              .icon {
                padding-top: 4px;
                margin-right: 12px;
              }
            }
          }
        }
        .btnArea {
          margin-top: 25px;
          display: flex;
          align-items: center;
          @include media(sm) {
            margin-top: 26px;
          }
          .c-btnA {
            min-height: 40px;
            border-radius: 50vw;
            @include media(sm) {
              min-height: 33px;
              &:nth-child(1) {
                width: 57%;
                .jp &{
                  width: 55%;
                }
              }
              &:nth-child(2) {
                width: 40%;
                .jp &{
                  width: 43%;
                }
              }
            }
            +.c-btnA {
              margin-left: 21px;
              @include media(sm) {
                margin-left: 9px;
                @media screen and (max-width: 360px) {
                  margin-left: 0;
                }
              }
            }
          }
        }
        .certArea{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;
          margin-top: 30px;
          @include media(sm) {
            width: 100%;
            gap: 5px;
            margin-top: 25px;
          }
          &__imageArea{
            img{
              width: 100px;
              @include media(sm) {
                width: 90%;
              }
            }
          }
          &__textArea{
            width: 360px;
            @include media(sm) {
              width: unset;
            }
            .text{
              @include fz(14, 22.4, 400);
              .jp &{
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 13px;
              }
            }
          }
        }
      }
      .linkArea {
        padding-top: 75px;
        @include media(sm) {
          padding-top: 30px;
        }
        .pageLink {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(5, 1fr);
          column-gap: 105px;
          row-gap: 15px;
          .jp &{
            column-gap: 30px;
          }
          @include media(sm) {
            column-gap: 66px;
            row-gap: 10px;
            .jp &{
              column-gap: 0;
            }
          }
          .navItem {
            @include fz(16, 22.4, 400);
            color: $dblue;
            .jp &{
              font-weight: 700;
            }
            @include media(sm) {
              font-size: 14px;
            }
            &--flex {
              display: flex;
              align-items: center;
              .icon {
                margin-left: 6px;
                vertical-align: baseline;
              }
            }
          }
        }
        .snsArea {
          margin-top: 20px;
          display: flex;
          align-items: center;
          .snsItem {
            +.snsItem {
              margin-left: 15px;
            }
          }
        }
      }
    }
    &__low {
      margin-top: 25px;
      text-align: center;
      @include media(sm) {
        margin-top: 25px;
        text-align: left;
      }
      .copy {
        @include fz(16, 21, 400);
        color: $dblue;
        @include media(sm) {
          @include fz(14, 21, 400);
        }
      }
    }
  }
}

// Cookie Consent（ここから）
.cookie{
  &-consent{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 16px;
    line-height: 1.8;
    color: #fff;
    background: rgba(0,0,0,.7);
    padding: 2rem;
    box-sizing: border-box;
    visibility: hidden;
    z-index: 1;
    @include media(sm) {
      flex-direction: column;
      padding: 2rem 2rem 3rem;
    }
  }
  &-flex{
    display: flex;
    @include media(sm) {
      align-items: center;
      gap: 2rem;
    }
  }
  &-agree,&-reject{
    color: #fff;
    background: linear-gradient(to right, #3DBBE4, #3699E9);
    padding: 0.5rem 4rem;
    border-radius: 50vw;
    font-weight: 700;
    .en &{
      padding: 0.3rem 4rem 0.7rem;
      font-weight: 400;
    }
    &:hover{
      cursor: pointer;
    }
  }
  &-agree{
    margin-left: 5rem;
    @include media(sm) {
      margin-left: 0;
    }
  }
  &-reject{
    margin-left: 2rem;
    @include media(sm) {
      margin-left: 0;
    }
  }
  &-text{
    a{
      color: #fff;
      text-decoration: underline;
    }
    @include media(sm) {
      font-feature-settings: "palt";
      letter-spacing: 1px;
      margin-bottom: 1em;
    }
  }
}
// Cookie Consent（ここまで）