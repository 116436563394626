@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

#seminar {
  &.l-main{
    margin-top: 0;
  }
  .contents{
    font-family: "Noto Sans JP", Sans-serif;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.9;
    color: #3c3c3c;
    @include media(sm) {
      font-size: 1.6rem;
    }
    h2{
      font-size: 3.8rem;
      font-weight: 600;
      text-align: center;
      &:after{
        content: '';
        width: 11rem;
        height: 1px;
        background-color: #3c3c3c;
        display: block;
        text-align: center;
        margin: 1.5rem auto 0;
      }
      @include media(sm) {
        font-size: 3rem;
      }
    }
    a{
      color: #004e98;
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
  .p-sec02,
  .p-sec03,
  .p-sec04{
    padding: 8rem 0 10rem;
    @include media(sm) {
      padding: 3rem 0 4rem;
    }
  }
  .p-sec01{
    padding-bottom: 10rem;
    @include media(sm) {
      padding-bottom: 4rem;
    }
    .image{
      padding: 7rem 0;
      @include media(sm) {
        padding: 3rem 0 4rem;
      }
    }
    p{
      padding-top: 5rem;
      @include media(sm) {
        padding-top: 3rem;
      }
      + p{
        padding-top: 1.8rem;
      }
    }
  }
  .p-sec03{
    .speakers{
      margin-top: 8rem;
      @include media(sm) {
        margin-top: 5rem;
      }
    }
    .speaker{
      display: flex;
      align-items: flex-start;
      gap: 5rem;
      @include media(sm) {
        display: block;
      }
      .img{
        width: 21rem;
        @include media(sm) {
          margin: 0 auto 2rem;
        }
        img{
          border: 1px solid #DCDCE4;
          border-radius: 5px;
        }
      }
      .txt{
        width: 65%;
        @include media(sm) {
          width: 100%;
        }
        h3{
          font-size: 2.3rem;
          font-weight: 700;
          @include media(sm) {
            font-size: 1.8rem;
          }
          + p{
            margin-top: 2rem;
            @include media(sm) {
              margin-top: 0.3rem;
            }
          }
        }
        p{
          font-size: 1.6rem;
          font-weight: 500;
          @include media(sm) {
            font-size: 1.3rem;
          }
          + p{
            margin-top: 2.5rem;
            @include media(sm) {
              margin-top: 1rem;
            }
          }
        }
      }
      + .speaker{
        margin-top: 8rem;
        @include media(sm) {
          margin-top: 5rem;
        }
      }
    }
  }
  .p-sec02,
  .p-sec04{
    background: #DEF9FFAB;
    table{
      margin-top: 8rem;
      @include media(sm) {
        margin-top: 5rem;
      }
      tr{
        &:not(:last-child){
          th,
          td{
            padding-bottom: 5rem;
            @include media(sm) {
              font-size: 1.6rem;
              padding-bottom: 3rem;
            }
          }
        }
      }
      th{
        width: 26rem;
        line-height: 1.6;
        @include media(sm) {
          padding-top: 0;
        }
      }
      td{
        h3,h4{
          font-size: 2rem;
          line-height: 1.4;
          @include media(sm) {
            font-size: 1.6rem;
          }
        }
        p{
          font-size: 1.6rem;
          @include media(sm) {
            font-size: 1.3rem;
          }
        }
        h3,p{
          +p,+h3{
            margin-top: 1.5rem;
          }
        }
        h4{
          +p{
            margin-top: 0.7rem;
            strong{
              display: block;
              margin-bottom: 0.7rem;
              @include media(sm) {
                margin-bottom: 0;
              }
            }
            +h4{
              margin-top: 4rem;
              @include media(sm) {
                margin-top: 2.5rem;
              }
            }
          }
        }
        strong{
          font-weight: 700;
        }
      }
    }
  }
  .p-sec02{
    .contents{
      @include media(sm) {
        // overflow-x: scroll;
      }
    }
    table{
      td{
        padding-top: 3px;
      }
      @include media(sm) {
        // min-width: 510px;
        // width: 100%;
        tr{
          display: grid;
          th{
            // width: 16rem;
            padding-bottom: 2rem !important;
          }
          td{
            width: calc(100vw - 40px);
          }
          &:not(:last-child){
            td{
              padding-bottom: 5rem !important;
            }
          }
        }
      }
    }
  }
  .p-sec04{
    table{
      margin-bottom: 14rem;
      @include media(sm) {
        margin-bottom: 6rem;
      }
      th{
        padding-top: 0px;
        @include media(sm) {
          width: 10rem;
        }
      }
      td{
        span{
          font-weight: 700;
        }
      }
    }
  }
  .btnArea {
    text-align: center;
    .c-btn-seminar{
      display: inline-block;
      background: #004E98;
      color: #fff;
      text-decoration: none;
      font-size: 1.8rem;
      font-weight: 700;
      padding: 20px 40px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 2rem;
      transition: 0.3s;
      &:hover{
        display: inline-block;
        transform: scale(1.05);
        opacity: unset;
      }
      @include media(sm) {
        padding: 10px 30px;
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }
    }
    p{
      display: none;
    }
    &.close{
      .c-btn-seminar{
        background: #adadad;
      }
      p{
        display: block;
        color: #D70C18;
        padding: 0;
      }
    }
  }
  footer{
    text-align: center;
    padding: 5rem 0 11rem;
    @include media(sm) {
      padding: 3rem 0 5rem;
    }
    .logo{
      img {
        width: 90px;
        height: auto;
        @include media(sm) {
          width: 85px;
        }
      }
    }
  }
}


#seminar-thanks{
  &.l-main{
    margin-top: 2rem;
    header{
      padding: 0 2rem;
      position: fixed;
      @include media(sm) {
        position: unset;
      }
    }
  }
  .p-sec01{
    font-family: "Noto Sans JP", Sans-serif;
    padding: 14rem 0 10rem;
    text-align: center;
    @include media(sm) {
      padding: 6rem 0 10rem;
    }
    h1{
      font-size: 3.6rem;
      font-weight: 600;
      color: #004e98;
      margin-bottom: 7rem;
      @include media(sm) {
        font-size: 2.7rem;
        line-height: 1.4;
        margin-bottom: 5rem;
      }
    }
    p{
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.8;
      @include media(sm) {
        font-size: 1.6rem;
        line-height: 1.8;
      }
      + p{
        margin-top: 2rem;
      }
      &:nth-child(3){
        margin-top: 4rem;
      }
      &.contact{
        font-size: 1.8rem;
        line-height: 1.8;
        margin-top: 5rem;
        @include media(sm) {
          font-size: 1.4rem;
        }
        span{
          display: block;
          color: #004e98;
          font-weight: 600;
        }
        a{
          color: #004e98;
        }
      }
    }
  }
}