@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

.c-inner--cus{ //2024-04-04 Sansanリクエストで追加
  max-width: 49vw; // max-width: 46vw;
  margin-left: 8vw; // margin-left: 9.37vw;
  @include media(sm) {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
#seminar-customize {
  &.l-main{
    margin-top: 30px;
    @include media(sm) {
      margin-top: 20px;
    }
    a.anchor {
      display: block;
      padding-top: 40px;
      margin-top: -40px;
    }
    header{
      position: fixed;
      top: 0;
      left: 0;
      padding: 25px 0 25px 9.37vw; //2024-04-04 Sansanリクエスト前 padding: 25px 40px; 
      z-index: 1;
      width: 60%; //2024-04-04 Sansanリクエスト前 width: 72%;
      height: 80px;
      background: #fff;
      @include media(sm) {
        padding: 13px 20px;
        width: 100%;
        height: 50px;
      }
      .logo{
        display: block;
        width: 85px;
        @include media(sm) {
          width: 70px;
        }
      }
    }
    .section-wrap{
      display: flex;
      position: relative;
      @include media(sm) {
        flex-direction: column;
      }
      .section-left{
        width: 58.5%; //2024-04-04 Sansanリクエスト前 width: 72%;
        position: relative;
        padding-top: 9rem;
        @include media(sm) {
          width: 100%;
          padding-top: 5rem;
        }
      }
      .section-right{
        width: 32%;
        position: fixed;
        top: 0;
        right: 8vw; //2024-04-04 Sansanリクエスト前 right: 0;
        @include media(sm) {
          position: unset;
          width: 100%;
        }
        .form-container{
          padding: 4rem 3rem;
          overflow-y: scroll;
          height: 100vh;
          max-width: 500px;
          @include media(sm) {
            padding: 3rem;
            overflow-y: visible;
            height: unset;
          }
          .form-title{
            h2{
              font-weight: 700;
              margin-bottom: 4rem;
              @include media(sm) {
                font-size: 2rem;
              }
              &:after{
                content: '';
                width: 3.5rem;
                height: 1px;
                background-color: #3c3c3c;
                display: block;
                margin: 3rem 0 0;
              }
            }
          }
          .form-wrap{
            p.closed{
              color: #D70C18;
              font-weight: 700;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .contents{
    font-family: "Noto Sans JP", Sans-serif;
    font-size: 1.5rem; //2024-04-04 Sansanリクエスト前 font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9;
    color: #3c3c3c;
    @include media(sm) {
      font-size: 1.4rem;
    }
    h2{
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 4rem;
      @include media(sm) {
        font-size: 2.3rem;
        margin-bottom: 1.5rem;
      }
    }
    a{
      color: #004e98;
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
  .p-sec02,
  .p-sec03,
  .p-sec04{
    padding: 4rem 0 5rem;
    @include media(sm) {
      padding: 3.5rem 0;
    }
  }
  .p-sec01{
    padding-bottom: 5rem;
    @include media(sm) {
      padding-bottom: 3.5rem;
    }
    .title{
      p{
        font-size: 20px; // 2025-01-17 Sansanリクエスト前 font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;
        font-feature-settings: 'palt';
        letter-spacing: 0.3px; // 2025-01-17 Sansanリクエスト前 letter-spacing: 0.7px;
        @include media(sm) {
          font-size: 18px;
          margin-bottom: 12px;
        }
      }
      h1{
        font-size: 25px; // 2025-01-17 Sansanリクエスト前 font-size: 34px; 2024-04-04 Sansanリクエスト前 font-size: 42px;
        font-weight: 600;
        line-height: 1.3;
        font-feature-settings: 'palt';
        letter-spacing: 1px; // 2025-01-17 Sansanリクエスト前 letter-spacing: 1.5px;
        @include media(sm) {
          font-size: 24px;
        }
        span{
          display: block;
          font-size: 90%; // 2025-01-17 Sansanリクエスト前 font-size: 75%; font-size: 80%;
          margin-top: 0.5rem;
        }
      }
    }
    .image{
      padding: 5rem 0;
      @include media(sm) {
        padding: 3rem 0 1rem;
      }
    }
    .contents{
      p{
        padding-top: 2rem;
        line-height: 1.8;
        @include media(sm) {
          padding-top: 3rem;
        }
        + p{
          padding-top: 1.8rem;
        }
      }
    }
  }
  .p-sec03{
    .speakers{
      margin-top: 5rem;
      @include media(sm) {
        margin-top: 1.5rem;
      }
    }
    .speaker{
      display: flex;
      align-items: flex-start;
      gap: 5rem;
      @include media(sm) {
        display: block;
      }
      .img{
        width: 21rem;
        @include media(sm) {
          margin: 0 auto 2rem;
        }
        img{
          border: 1px solid #DCDCE4;
          border-radius: 5px;
        }
      }
      .txt{
        width: 65%;
        @include media(sm) {
          width: 100%;
        }
        h3{
          font-size: 2rem;
          font-weight: 700;
          @include media(sm) {
            font-size: 1.8rem;
          }
        }
        p{
          font-size: 1.4rem;
          font-weight: 500;
          @include media(sm) {
            font-size: 1.3rem;
          }
          &:first-of-type{
            line-height: 1.5;
          }
          &:last-child{
            line-height: 1.8;
            @include media(sm) {
              line-height: 1.7;
            }
          }
          + p{
            margin-top: 1.5rem;
            @include media(sm) {
              margin-top: 1rem;
            }
          }
        }
      }
      + .speaker{
        margin-top: 8rem;
        @include media(sm) {
          margin-top: 5rem;
        }
      }
    }
  }
  .p-sec02,
  .p-sec04{
    background: #DEF9FFAB;
    table{
      margin-top: 3rem;
      @include media(sm) {
        margin-top: 2rem;
      }
      &:not(:last-child){
        margin-bottom: 5rem;
      }
      th{
        @include media(sm) {
          padding-top: 0;
        }
      }
      td{
        font-feature-settings: 'palt';
        letter-spacing: 0.3px;
        h3,h4{
          font-size: 2rem;
          line-height: 1.4;
          @include media(sm) {
            font-size: 1.6rem;
          }
        }
        p{
          font-size: 1.6rem;
          @include media(sm) {
            font-size: 1.4rem;
          }
        }
        h3,p{
          +p,+h3{
            margin-top: 1.5rem;
          }
        }
        h4{
          +p{
            margin-top: 0.7rem;
            strong{
              display: block;
              margin-bottom: 0.7rem;
              @include media(sm) {
                margin-bottom: 0;
              }
            }
            +h4{
              margin-top: 4rem;
              @include media(sm) {
                margin-top: 2.5rem;
              }
            }
          }
        }
        strong{
          font-weight: 700;
        }
      }
    }
  }
  .p-sec02{
    .contents{
      > p{
        font-weight: 700;
        text-decoration: underline;
        text-underline-offset: 12px;
        text-decoration-color: #3c3c3c;
        @include media(sm) {
          text-underline-offset: 7px;
        }
      }
    }
    table{
      tr{
        th{
          width: 7rem;
        }
        @include media(sm) {
          display: grid;
          &:not(:last-child){
            th,
            td{
              padding-bottom: 0.5rem;
              @include media(sm) {
                padding-bottom: 3rem;
              }
            }
          }
          th{
            padding-bottom: 0 !important;
          }
          td{
            font-feature-settings: 'palt';
            letter-spacing: 0.3px;
            width: calc(100vw - 40px);
            @include media(sm) {
              // text-indent: -1rem;
              // padding-left: 1rem;
            }
          }
          &:not(:last-child){
            td{
              padding-bottom: 2.5rem !important;
            }
          }
        }
      }
    }
  }
  .p-sec04{
    table{
      margin-bottom: 14rem;
      @include media(sm) {
        margin-bottom: 1rem;
      }
      tr{
        &:not(:last-child){
          th,
          td{
            padding-bottom: 0.5rem;
            @include media(sm) {
              padding-bottom: 0.3rem;
            }
          }
        }
      }
      th{
        width: 10rem;
        padding-top: 0;
        @include media(sm) {
          width: 8rem;
        }
      }
      td{
        span{
          font-weight: 700;
        }
      }
    }
  }
  .btnArea {
    text-align: center;
    margin-top: 4rem;
    .c-btn-seminar{
      display: inline-block;
      background: #004E98;
      color: #fff;
      text-decoration: none;
      font-size: 1.6rem;
      font-weight: 700;
      padding: 10px 30px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 1rem;
      transition: 0.3s;
      &:hover{
        display: inline-block;
        transform: scale(1.05);
        opacity: unset;
      }
    }
    p{
      display: none;
    }
    &.close{
      .c-btn-seminar{
        background: #adadad;
      }
      p{
        display: block;
        color: #D70C18;
        padding: 0;
        font-weight: 600;
      }
    }
  }
  footer{
    text-align: center;
    padding: 5rem 0 11rem;
    @include media(sm) {
      padding: 3rem 0 5rem;
    }
    .logo{
      img {
        width: 90px;
        height: auto;
        @include media(sm) {
          width: 85px;
        }
      }
    }
  }
}