@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-titA {
  position: relative;
  margin-bottom: 3rem;
  @include media(sm) {
    margin-bottom: 20px;
    .jp &{
      margin-bottom: 25px;
    }
  }
  &__titWrap {
    display: flex;
    align-items: center;
  }
  &__en {
    color: $tit;
    @include fz(36, 45, 400);
    margin-right: 70px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      font-size: 25px;
      margin-right: 20px;
      position: relative;
      z-index: 2;
      display: inline-block;
    }
  }
  &__bg {
    color: #CFE6FA;
    @include fz(120,88,400);
    margin-bottom: 10px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      @include fz(60, 40, 400);
      position: absolute;
      z-index: 0;
    }
  }
  &__jp {
    color: $tit;
    @include fz(20, 28, 400);
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      display: inline-block;
      font-size: 16px;
      position: relative;
      z-index: 2;
      margin-top: 5px;
      .jp &{
        margin-top: 10px;
      }
    }
  }
}
.c-titB {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__main {
    color: $tit;
    @include fz(36, 45, 400);
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      font-size: 25px;
      .jp &{
        font-size: 28px;
      }
    }
  }
  &__jp {
    color: $tit;
    @include fz(20,20,400);
    margin-top: 20px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      font-size: 14px;
      margin-top: 8px;
    }
  }
}
.c-titC {
  @include fz(28, 39.2, 400);
  position: relative;
  text-align: center;
  @include media(sm) {
    font-size: 22px;
  }
  .jp &{
    font-weight: 700;
  }
  &::after {
    content: '';
    width: 70px;
    height: 5px;
    background-color: #0E84E5;
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    @include media(sm) {
      bottom: -16px;
    }
  }
}