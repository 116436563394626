@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#func {
  .p-sec01 {
    padding: 5rem 0 8rem;
    @include media(sm) {
      padding: 4rem 0;
    }
    .c-inner {
      @include media(sm) {
        max-width: none;
        width: 100%;
      }
    }
    &__tit {
      @include fz(36, 45, 400);
      color: $tit;
      text-align: center;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 25px;
      }
    }
    &-contents {
      &__cat {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        background-color: #dfe8f0;
        min-height: 65px;
        border-radius: 50vw;
        margin-top: 49px;
        box-shadow: 4px 4px 10px rgba($color: #000000, $alpha: .3);
        @include media(sm) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px 5px;
          border-radius: 50vw;
          padding: 0;
          background-color: transparent;
          box-shadow: none;
          margin-top: 30px;
          margin-left: 20px;
          margin-right: 20px;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          @include fz(16, 20, 400);
          color: $gray;
          min-height: 35px;
          border-radius: 50vw;
          transition: .4s;
          text-align: center;
          padding-bottom: 2px;
          .en &{
            &:first-child{
              padding-bottom: 5px;
            }
          }
          .jp &{
            font-weight: 700;
          }
          @include media(sm) {
            @include fz(12, 13.2, 600);
            width: 100%;
            background-color: #CFE6FA;
            box-shadow: 0 3px 5px rgba($color: #CDDCEA, $alpha: .5);
            padding-bottom: 1px;
            .en &{
              &:first-child{
                padding-bottom: 3px;
              }
            }
          }
          &:hover{
            opacity: 1;
          }
          &.is-active {
            font-weight: bold;
            background-color: #0E84E5;
            color: #fff;
          }
        }
      }
      &__slide {
        display: flex;
        justify-content: center;
        margin-top: 59px;
        @include media(sm) {
          margin-top: 40px;
        }
      }
      .slideContents {
        .preContents {
          margin-top: 50px;
          @include media(sm) {
            margin-left: 20px;
            margin-right: 20px;
          }
          .tit {
            text-align: center;
            @include fz(24, 30, 400);
            margin-bottom: 40px;
            @include media(sm) {
              font-size: 20px;
              margin-bottom: 20px;
            }
            .jp &{
              font-weight: 700;
            }
          }
          .c-text16 {
            text-align: center;
            @include media(sm) {
              text-align: left;
            }
          }
        }
        .contentsBox {
          margin-top: 6rem;
          display: flex;
          justify-content: center;
          @include media(sm) {
            margin-top: 40px;
            flex-direction: column;
          }
          &:nth-child(even) {
            flex-direction: row-reverse;
            @include media(sm) {
              flex-direction: column;
            }
            .imageArea {
              margin-right: 0;
              margin-left: 43px;
              @include media(sm) {
                margin-left: 0;
              }
            }
          }
          .imageArea {
            flex-shrink: 0;
            margin-right: 43px;
            @include media(sm) {
              margin-right: 0;
              margin-bottom: 30px;
              img {
                width: 100%;
              }
            }
          }
          .textArea {
            @include media(sm) {
              margin-left: 20px;
              margin-right: 20px;
            }
            .listTit {
              @include fz(24, 30, 400);
              margin-bottom: 30px;
              @include media(sm) {
                font-size: 20px;
                margin-bottom: 20px;
              }
              .jp &{
                font-weight: 700;
                font-feature-settings: "palt";
                letter-spacing: 1.5px;
              }
            }
            .list {
              .listItem {
                .tit {
                  color: $tit;
                  @include fz(20,21,400);
                  margin-bottom: 15px;
                  @include media(sm) {
                    font-size: 17px;
                    margin-bottom: 10px;
                  }
                  .jp &{
                    font-weight: 700;
                  }
                }
                .c-text16{
                  .jp &{
                    letter-spacing: 1px;
                    @include media(sm) {
                      letter-spacing: 0.7px;
                    }
                  }
                }
                + .listItem {
                  margin-top: 35px;
                  @include media(sm) {
                    margin-top: 2.5rem;
                  }
                }
              }
            }
          }
        }
        .badgeArea {
          margin-top: 6rem;
          display: grid;
          grid-template-columns: repeat(6,1fr);
          gap: 32px 28px;
          @include media(sm) {
            grid-template-columns: repeat(2,1fr);
            gap: 15px 20px;
            margin-top: 40px;
          }
          .image{
            padding-left: 6px;
            @include media(sm) {
              display: flex;
              justify-content: center;
              padding-left: 3px;
            }
          }
          .badgeItem {
            @include media(sm) {
              &:nth-child(odd){
                padding-left: 3rem;
              }
              &:nth-child(even){
                padding-right: 3rem;
              }
            }
            .tit {
              text-align: center;
              margin-top: 6px;
              @include fz(16,20,400);
              .jp &{
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 14px;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
  .p-sec02 {
    background: linear-gradient(to right, #3DBBE4, #3699E9);
    padding: 4rem 0 5rem;
    &-box {
      display: flex;
      justify-content: center;
      align-items: center;
      @include media(sm) {
        flex-direction: column;
      }
      &__imageArea {
        margin-left: -50px;
        @include media(sm) {
          text-align: center;
          margin-left: 0;
        }
        img{
          width: 440px;
          @include media(sm) {
            width: 90%;
          }
        } 
      }
      &__textArea {
        margin-left: 100px;
        @include media(sm) {
          margin-left: 0;
          margin-top: 25px;
        }
        .tit {
          @include fz(35, 42, 500);
          color: #fff;
          @include media(sm) {
            @include fz(24, 33.6, 500);
          }
          .en &{
            @include media(sm) {
              line-height: 1.2;
            }
          }
          .jp &{
            font-weight: 700;
            span{
              @include fz(42, 58.8, 700);
              @include media(sm) {
                font-size: 28px;
              }
            }
          }
          small{
            font-size: 17px;
            display: block;
            margin-bottom: -4rem;
            margin-left: 2rem;
            .en &{
              margin-left: unset;
            }
            @include media(sm) {
              font-size: 13px;
              margin-bottom: -3.5rem;
              .en &{
                margin-bottom: -2.5rem;
              }
            }
          }
        }
        .btnArea {
          margin-top: 55px;
          display: flex;
          justify-content: flex-start;
          padding-left: 0.2rem;
          .jp &{
            padding-left: 2.2rem;
          }
          @include media(sm) {
            justify-content: center;
            margin-top: 25px;
          }
          .c-btnC{
            &:hover{
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
}