@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

.c-pager {
  &__pagenavi {
    display: flex;
    justify-content: center;
    align-items: center;
    a,
    span {
      margin: 0 14px;
      @include fz(16, 18, 700);
      color: $gray;
      @include media(sm) {
        margin: 0 7px;
      }
      &:hover {
        color: #00759d;
      }
      &.current {
        color: #00759d;
      }
    }
    a {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.wp-pagenavi{
  display: flex;
  justify-content: center;
  align-items: center;
  .pages{
    display: none;
  }
  a,
  span {
    margin: 0 14px;
    @include fz(16, 18, 700);
    color: $gray;
    @include media(sm) {
      margin: 0 7px;
    }
    &:hover {
      color: #00759d;
    }
    &.current {
      color: #00759d;
    }
  } 
  a {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}