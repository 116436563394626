@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
/*
  基本的にa,buttonタグにつける
  中央寄せなどは親要素で設定
*/
.c-btnA {
  background: linear-gradient(to right, #3DBBE4, #3699E9);
  width: 230px;
  min-height: 55px;
  @include fz(16,22,400);
  vertical-align: middle;
  color: #fff;
  border-radius: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  box-shadow: 4px 4px 4px rgba($color: #0E84E5, $alpha: .3);
  @include media(sm) {
    width: 210px;
    min-height: 33px;
    @include fz(14,17,400);
  }
  .jp &{
    @include fz(16,17.6,700);
    text-align: center;
    display: grid;
    padding-bottom: 1px;
    @include media(sm) {
      font-weight: 400;
    }
    span{
      font-size: 13px;
      margin-top: -13px;
      @include media(sm) {
        margin-top: -7px;
      }
    }
  }
  &--company {
    img {
      margin-left: 10px;
    }
  }
}
.c-btnB {
  display: flex;
  justify-content: center;
  align-items: center;
  @include fz(15, 20, 400);
  width: 164px;
  min-height: 35px;
  // border: 1px solid #000;
  border-radius: 50vw;
  padding-bottom: 3px;
  background: linear-gradient(90deg, #3dbbe4, #3699e9);
  box-shadow: 4px 4px 4px rgba(14, 132, 229, 0.3);
  color: #fff;
  @include media(sm) {
    font-size: 14px;
    width: 169px;
    min-height: 33px;
    margin-bottom: 18px;
    padding-bottom: 2px;
  }
  .jp &{
    font-weight: 700;
    @include media(sm) {
      font-size: 15px;
      font-weight: 400;
      width: 200px;
      min-height: 40px;
    }
  }
}
.c-btnC {
  border: 1px solid #3699E9;
  width: 230px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  @include fz(16,22.4,400);
  border-radius: 50px;
  @include media(sm) {
    min-height: 33px;
    width: 210px;
    @include fz(16,20,400);
  }
  .jp &{
    font-weight: 700;
    @include media(sm) {
      font-weight: 400;
    }
  }
  &:hover {
    background-color: #3699E9;
    color: #fff;
    opacity: 1;
  }
}
.c-btnD {
  width: 230px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50vw;
  background: linear-gradient(to right, #3DBBE4, #3699E9);
  padding: 1px;
  &:hover {
    background: #3699E9;
    opacity: 1;
    & .c-btnD__contents{
      background: transparent;
    }
    & .c-btnD__text{
      color: #fff;
      &::before{
        background: url(../../images/arrow_back-wh.svg) no-repeat;
      }
    }
  }
  @include media(sm) {
    width: 210px;
    min-height: 33px;
  }
  &__contents {
    background: #fff;
    border-radius: 50vw;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text {
    @include fz(16,22.4,400);
    position: relative;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      padding-bottom: 2px;
      .jp &{
        padding-bottom: 1px;
      }
    }
    &::before {
      position: absolute;
      top: 50%;
      left: -30px;
      transform: translateY(-50%);
      display: block;
      content: '';
      background: url(../../images/arrow_back.svg) no-repeat;
      background-size: 9px;
      width: 7px;
      height: 14px;
      @include media(sm) {
        top: 48%;
      }
    }
  }
}
