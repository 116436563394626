@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

.c-form {
  &-list {
    &__item {
      +.c-form-list__item {
        margin-top: 1.8rem;
      }
      .titArea {
        min-width: 218px;
        margin-bottom: 0.5rem;
        @include media(sm) {
          min-width: none;
          margin-bottom: 10px;
        }
        .tit {
          @include fz(14, 22.4, 700);
        }
        .req {
          color: #e61c24;
          @include fz(14, 16.8, 700);
        }
      }
      .contentsArea {
        width: 100%;
        position: relative;
        input,
        select {
          width: 100%;
          border: 1px solid #c87872;
          border-radius: 3px;
          padding: 5px 30px;
          @include fz(16, 25.6, 500);
          @include media(sm) {
            padding: 5px 15px;
          }
          &::placeholder {
            color: #dbdfe3;
          }
        }
        select {
          &:invalid {
            color: #dbdfe3;
          }
          option {
            color: $gray;
            &:first-child {
              color: #dbdfe3;
            }
          }
        }
        &--select {
          &::after {
            content: '';
            background: url(/assets/images/download_icon_arrow.svg) no-repeat;
            background-size: 12px;
            width: 12px;
            height: 9px;
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            pointer-events: none;
            @include media(sm) {
              right: 15px;
            }
          }
        }
      }
    }
  }
  &__agree {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    label {
      position: relative;
      input {
        opacity: 0;
        &:checked {
          +span {
            &::after {
              content: '';
              width: 30px;
              height: 10px;
              border-left: 4px solid #00759d;
              border-bottom: 4px solid #00759d;
              transform: rotate(-45deg);
              @include media(sm) {
                width: 15px;
                height: 8px;
                border-left: 3px solid #00759d;
                order-bottom: 3px solid #00759d;
              }
            }
          }
        }
      }
      span {
        position: relative;
        padding-left: 25px;
        @include fz(14, 22.6, 700);
        @include media(sm) {
          padding-left: 7px;
        }
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          display: block;
          content: "";
          width: 15px;
          height: 15px;
          background-color: #fff;
          border: 2px solid #80a5c4;
          border-radius: 5px;
          @include media(sm) {
            left: -15px;
          }
        }
        &::after {
          position: absolute;
          top: 5px;
          left: 5px;
          display: block;
          content: "";
          @include media(sm) {
            top: 4px;
            left: -12px;
          }
        }
      }
    }
  }
  &__btn {
    display: flex;
    justify-content: flex-start;
    margin-top: 25px;
    @include media(sm) {
      justify-content: center;
    }
  }
}