@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

.c-mv {
  background: url(../../images/about_mv@2x.jpg) no-repeat;
  background-size: cover;
  background-position: right;
  padding: 57px 0 51px;
  @include media(sm) {
    background: url(../../images/about_mv_sp@2x.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    padding: 2rem 0;
  }
  &__tit {
    @include fz(40, 50, 400);
    color: $tit;
    margin-bottom: 33px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      font-size: 28px;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  &__btn {
    .c-btnA {
      .jp &{
        @include media(sm) {
          min-height: 42px;
        }
      }
    }
  }
}
