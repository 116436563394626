@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#price {
  .p-sec01 {
    padding: 5rem 0 7.5rem;
    @include media(sm) {
      padding: 4rem 0 5rem;
    }
    &__text {
      text-align: center;
      @include fz(20, 40, 400);
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 16px;
        line-height: 1.4;
      }
    }
    &-contents {
      margin-top: 45px; // 2025-01-17 Sansan修正指示前 margin-top: 60px;
      @include media(sm) {
        margin-top: 3rem;
      }
      &__box {
        border: 3px solid #0e84e5;
        border-radius: 20px;
        padding: 30px;
        @include media(sm) {
          padding: 7px;
        }
        .list {
          .listItem {
            display: flex;
            @include media(sm) {
              flex-direction: column;
            }
            + .listItem {
              margin-top: 10px;
            }
            .tit {
              background-color: #0e84e5;
              color: #fff;
              @include fz(20, 32, 400);
              min-width: 240px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
              .jp &{
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 18px;
                line-height: 1.2;
                min-width: unset;
                margin-right: 0;
                min-height: 40px;
                padding: 5px 0;
              }
            }
            .content {
              background-color: #f5f5f5;
              padding: 30px;
              width: 75.96%;
              @include fz(17, 37.4, 400);
              .jp &{
                font-size: 16px;
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 15px;
                line-height: 1.7;
                padding: 25px;
                width: 100%;
                .jp &{
                  font-size: 14px;
                  font-feature-settings: "palt";
                  letter-spacing: 0.9px;
                }
                small{
                  display: block;
                  line-height: 1.5;
                  .jp &{
                    line-height: 1.7;
                  }
                }
              }
            }
            &:first-child {
              .tit {
                border-top-left-radius: 10px;
                @include media(sm) {
                  border-top-right-radius: 10px;
                }
              }
              .content {
                border-top-right-radius: 10px;
              }
            }
            &:last-child {
              .tit {
                border-bottom-left-radius: 10px;
                @include media(sm) {
                  border-bottom-right-radius: 0;
                  border-bottom-left-radius: 0;
                }
              }
              .content {
                border-bottom-right-radius: 10px;
                @include media(sm) {
                  border-bottom-left-radius: 10px;
                }
              }
            }
          }
        }
        &:nth-child(2) {
          .list {
            .listItem {
              .tit {
                background-color: #cfe6fa;
                color: $tit;
                flex-direction: column;
                @include media(sm) {

                }
                .caution {
                  @include fz(16, 20, 400);
                  .jp &{
                    font-weight: 700;
                  }
                  @include media(sm) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
        + .p-sec01-contents__box {
          margin-top: 12rem;
          position: relative;
          @include media(sm) {
            margin-top: 8rem;
          }
          &::before,
          &::after {
            position: absolute;
            content: "";
            width: 50px;
            height: 4px;
            background-color: #0e84e5;
            top: -65px;
            left: 50%;
            transform: translateX(-50%);
            @include media(sm) {
              width: 35px;
              top: -45px;
            }
          }
          &::after {
            transform: translateX(-50%) rotate(90deg);
          }
        }
      }
    }
  }
  .p-sec02 {
    padding: 6rem 0 8rem;
    background-color: #e7f3fc;
    @include media(sm) {
      padding: 5rem 0;
    }
    &__tit {
      text-align: center;
      @include fz(36, 45, 400);
      color: $tit;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 25px;
      }
    }
    &__text {
      color: $tit;
      @include fz(20, 28, 400);
      text-align: center;
      margin-top: 3rem;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 16px;
        margin-top: 1rem;
      }
    }
    &-contents {
      margin-top: 8rem;
      margin-left: auto;
      margin-right: auto;
      width: 300px;
      .jp &{
        width: 340px;
      }
      @include media(sm) {
        width: 250px;
        margin-top: 4rem;
      }
      &__item {
        position: relative;
        text-align: center;
        + .p-sec02-contents__item {
          margin-top: 8rem;
          @include media(sm) {
            margin-top: 6rem;
          }
          &::before {
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            background: url(../../images/price_sec02_arrow@2x.svg);
            width: 35px;
            height: 22px;
            background-size: 35px;
            background-repeat: no-repeat;
            @include media(sm) {
              top: -40px;
              width: 35px;
              background-size: 35px;
              height: 36px;
            }
          }
        }
        .stepImg {
          position: absolute;
          @include media(sm) {
            position: relative;
            text-align: center;
            margin-bottom: 1.5rem;
            top: auto !important;
            left: auto !important;
            right: auto !important;
          }
          img {
            @include media(sm) {
              width: 180px;
            }
          }
        }
        &:nth-child(1) {
          .stepImg {
            top: -30px;
            .jp &{
              top: -30px;
            }
          }
        }
        &:nth-child(2) {
          .stepImg {
            top: -25px;
            .jp &{
              top: -50px;
            }
          }
        }
        &:nth-child(3) {
          .stepImg {
            top: -40px;
            .jp &{
              top: -50px;
            }
          }
        }
        &:nth-child(4) {
          .stepImg {
            top: -12px;
            .jp &{
              top: -65px;
            }
          }
        }
        &:nth-child(2n+1) {
          .stepImg {
            left: -300px;
            .jp &{
              left: -270px;
            }
          }
        }
        &:nth-child(2n) {
          .stepImg {
            right: -300px;
            .jp &{
              right: -270px;
            }
          }
        }
        .tit {
          @include fz(24, 30, 400);
          text-align: center;
          color: $tit;
          margin-bottom: 20px;
          .jp &{
            font-weight: 700;
          }
          @include media(sm) {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
        .c-text16 {
          display: inline-block;
          text-align: left;
          a{
            color: $tit;
            text-decoration: underline;
            text-underline-offset: 2px;
          }
        }
      }
    }
  }
  .p-sec03 {
    padding: 6rem 0 7rem;
    @include media(sm) {
      padding: 5rem 0 4rem;
    }
    &__tit {
      text-align: center;
      color: $tit;
      @include fz(36, 45, 400);
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 25px;
      }
      span{
        display: block;
        font-size: 20px;
        margin-top: 4px;
        @include media(sm) {
          font-size: 15px;
        }
      }
    }
    &__subTit {
      color: $tit;
      @include fz(20, 28, 400);
      text-align: center;
      margin-top: 3rem;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        font-size: 16px;
        margin-top: 1rem;
      }
    }
    &__text {
      text-align: center;
      margin-top: 30px;
      @include media(sm) {
        text-align: left;
        margin-top: 25px;
      }
    }
    &-contents {
      margin-top: 80px;
      @include media(sm) {
        margin-top: 3rem;
      }
      &__item {
        display: flex;
        @include media(sm) {
          flex-direction: column;
        }
        &:nth-child(even) {
          flex-direction: row-reverse;
          @include media(sm) {
            flex-direction: column;
          }
          .imageArea {
            margin-right: 0;
            margin-left: 40px;
            @include media(sm) {
              margin-left: -20px;
              margin-right: -20px;
            }
          }
        }
        .block{
          display: block;
          .textArea{
            + .textArea{
              margin-top: 3rem;
              .jp &{
                margin-top: 5rem;
              }
              @include media(sm) {
                margin-top: 2rem;
                .jp &{
                  margin-top: 3rem;
                }
              }
            }
          }
        }
        + .p-sec03-contents__item {
          margin-top: 80px;
          @include media(sm) {
            margin-top: 4rem;
          }
        }
        .imageArea {
          flex-shrink: 0;
          margin-right: 40px;
          @include media(sm) {
            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: 1rem;
          }
        }
        .textArea {
          .tit {
            @include fz(24, 30, 400);
            margin-bottom: 20px;
            .jp &{
              font-weight: 700;
              margin-bottom: 30px;
            }
            @include media(sm) {
              font-size: 20px;
              margin-bottom: 5px;
              .jp &{
                margin-bottom: 5px;
              }
            }
            small{
              display: block;
              font-size: 20px;
              margin-top: 3px;
              @include media(sm) {
                font-size: 15px;
                margin-top: 4px;
              }
            }
          }
          .btnArea {
            margin-top: 78px;
            @include media(sm) {
              display: flex;
              justify-content: center;
              margin-top: 50px;
            }
          }
        }
      }
    }
  }
}
