@charset "utf-8";
//pc
$breakPointPc: 1380px;
//tab
$breakPointTab: 1025px;
//sm
$breakPoint: 769px;
//min
$breakPointMin: 501px;

$blue:#0075ac;
$tblue: #32739E;
$dblue: #0c244c;
$gray: #4b5158;
$lgray: #eaeef2;
$tit: #004E98;