@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-wysywig {
  @include fz(17, 34, 400);
  .jp &{
    font-size: 16px;
    font-weight: 700;
  }
  @include media(sm) {
    font-size: 15px;
    .jp &{
      font-size: 14px;
    }
  }
  *:first-child {
    margin-top: 0 !important;
  }
  *:last-child {
    margin-bottom: 0 !important;
  }
  h1 {
    @include fz(40, 50, 400);
    margin-bottom: 24px;
    color: $tit;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      font-size: 30px;
    }
  }
  h2 {
    @include fz(36, 45, 400);
    color: $tit;
    padding: 5px 10px;
    margin-bottom: 24px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      font-size: 25px;
    }
  }
  h3 {
    position: relative;
    @include fz(28, 39.2, 400);
    color: #004E98;
    margin-bottom: 25px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      font-size: 22px;
    }
  }
  h4 {
    @include fz(20, 28, 400);
    color: #004E98;
    padding-left: 11px;
    margin-bottom: 20px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      font-size: 16px;
    }
  }
  h5 {
    @include fz(16, 16, 400);
    padding-bottom: 6px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      @include fz(16, 16, 400);
    }
  }
  h6 {
    @include fz(16, 16, 400);
    margin-top: 15px;
    margin-bottom: 10px;
    .jp &{
      font-weight: 700;
    }
    @include media(sm) {
      @include fz(16, 16, 400);
    }
  }
  h1,
  h2,
  p,
  pre,
  ul,
  ol,
  blockquote,
  table {
    +h1,
    +h2 {
      margin-top: 20px;
    }
  }
  p, pre, ul {
    margin-bottom: 14px;
  }
  ul li {
    position: relative;
    padding-left: 25px;
    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-color: #00759d;
      border-radius: 50%;
    }
  }
  ol {
    margin-bottom: 20px;
  }
  ol li {
    margin-left: 25px;
    list-style: decimal;
  }
  table {
    margin-bottom: 20px;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  hr {
    margin: 50px 0;
  }
  .image {
    position: relative;
    margin-bottom: 4rem;
    @include media(sm) {
      margin-bottom: 40px;
    }
  }
  figure{
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
  img {
    float: none !important;
    display: block !important;
    height: auto;
    margin-top: 84px;
    margin-bottom: 84px;
    position: relative;
    @include media(sm) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  blockquote {
    border: 1px solid #ddd;
    margin-bottom: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2.3rem;
    padding-bottom: 2.1rem;
  }
  blockquote>p {
    margin-bottom: 0;
  }
  .aligncenter {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .alignright {
    margin-left: auto;
  }
  .alignleft {
    margin-right: auto;
  }
}