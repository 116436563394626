@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
* {
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

html {
	font-size: 10px;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
	// font-family: sofia-pro, "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "游ゴシック体",
	// 	YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Meiryo", sans-serif;
	font-family: sofia-pro, sans-serif;
	font-size: 1.6rem;
	line-height: 1;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	color: #000;
	min-width: 1080px;
	@media all and (-ms-high-contrast: none), print {
		// font-family:'メイリオ', 'Meiryo', sans-serif;
	}
	@include media(sm) {
		min-width: auto;
	}
	&.jp{
		font-family: sofia-pro, 游ゴシック体, YuGothic, 游ゴシック, Yu Gothic, メイリオ, Hiragino Kaku Gothic ProN, Hiragino Sans, Helvetica, Roboto, sans-serif;
	}
}

a,
button {
	text-decoration: none;
	color: #000;
	outline: none;
	@include media(lg) {
		transition: 0.3s ease;
		&:hover {
			opacity: 0.7;
		}
		path,
		img,
		p {
			transition: 0.3s ease;
		}

	}
}

// image

img,
svg,
video {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
	display: inline-block;
	image-rendering: -webkit-optimize-contrast;
}

// table

table {
	border-collapse: collapse;
	max-width: 100%;
	width: 100%;
	th,
	td {
		vertical-align: top;
	}
}

// IE11のクリアボタンを非表示

input::-ms-clear {
	display: none;
}

ul,
ol {
	list-style-position: inside;
	list-style: none;
}

input,
button,
textarea,
select {
	font-family: inherit;
}

select::-ms-expand {
	display: none;
}

button {
	&:focus {
		outline: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
}
p {
}
@include media(tab) {
	@include media(sm) {
		@include media(min) {
		}
	}
}
@include media(minup) {
	@include media(lg) {
		@include media(tabup) {
		}
	}
}
