@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#privacy,
#cnpdpl{
  a{
    color: $tit;
    // text-decoration: underline;
    // text-underline-offset: 2px;
  }
  .p-sec01 {
    padding: 80px 0;
    @include media(sm) {
      padding: 30px 0 90px
    }
    &-textArea {
      + .p-sec01-textArea{
        margin-top: 40px;
        @include media(sm) {
          margin-top: 30px;
        }
      } 
      .tit{
        @include fz(20, 32, 400);
        color: #00759d;
        margin-bottom: 10px;
        .jp &{
          font-weight: 700;
        }
        @include media(sm) {
          @include fz(18, 28.8, 400);
        }
      }
      .text {
        line-height: 1.6;
      }
    }
    &-formArea {
      margin-top: 86px;
      @include media(sm) {
        margin-top: 50px;
      }
    }
  }
  .p-sec02 {
    padding: 50px 0 80px;
    @include media(sm) {
      padding: 30px 0 90px
    }
    &__tit{
      margin-bottom: 60px;
      .c-titA__main{
        display: block;
        margin: 0 auto;
        text-align: center;
        color: $tblue;
        @include fz(40,48,400);
        @include media(sm) {
          @include fz(28,33.6,400);
        }
      }
    }
    &-textArea {
      + .p-sec02-textArea{
        margin-top: 40px;
      } 
      .tit{
        @include fz(18, 28.8, 400);
        margin-bottom: 10px;
        .jp &{
          font-weight: 700;
        }
        @include media(sm) {
          font-size: 16px;
        }
      }
      .text {
        line-height: 1.6;
        a{
          @include media(sm) {
            word-break: break-all;
          }
        }
      }
    }
  }
}

#privacy{
  .c-mv__tit{
    text-align: center;
    font-size: 42px;
    .jp &{
      font-size: 38px;
    }
  }
}

#cnpdpl {
  .c-mv__tit{
    text-align: center;
    font-size: 32px;
    @include media(sm) {
      font-size: 26px;
    }
    .jp &,
    .cn &{
      font-size: 30px;
      @include media(sm) {
        font-size: 25px;
      }
    }
  }
}