@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#top {
  .mvWrap {
    background: url(../../images/top_mv@2x.png) no-repeat;
    background-size: 100%;
    background-position: center top;
    @include media(sm) {
      background: url(../../images/top_mv_sp.png) no-repeat;
      background-size: 100%;
      background-position: center -80px;
    }
  }
  .p-mv{
    padding: 15rem 0 4rem; // padding: 11rem 0 4rem;
    .jp &{
      padding: 15rem 0 4rem;
    }
    @include media(sm){
      padding: 27rem 0 6.5rem; // padding: 23rem 0 9rem;
      .jp &{
        padding: 27rem 0 6.5rem;
      }
    }
    &-textArea{
      &__tit{
        color: $tit;
        margin-bottom: 3rem; // margin-bottom: 20px;
        @include fz(65, 78, 400); // @include fz(50, 60, 400);
        .jp &{
          margin-bottom: 13px;
          font-size: 60px;
          font-weight: 700;
          line-height: 1.5;
        }
        @include media(sm){
          font-size: 32px; // font-size: 39px; // @include fz(28, 33.6, 400);
          margin-bottom: 15px; // margin-bottom: 9px;
          .jp &{
            margin-bottom: 10px;
            font-size: 35px;
            font-weight: 700;
            line-height: 1.4;
          }
        }
      }
      &__text{
        @include fz(21, 35.7, 400); // @include fz(22, 37.4, 400); // @include fz(22, 30.8, 400);
        .jp &{
          font-size: 22px;
          font-weight: 700;
          line-height: 2;
        }
        @include media(sm){
          // @include fz(16, 20.8, 400);
          font-size: 16px;
          line-height: 1.5;
          .jp &{
            font-size: 18px;
            line-height: 1.6;
          }
        }
      }
      &__btnArea{
        margin-top: 35px; // margin-top: 40px;
        display: flex;
        align-items: center;
        gap: 26px;
        @include media(sm) {
          margin-top: 20px;
          gap: 15px;
          flex-direction: column;
          .jp &{
            margin-top: 25px;
            .c-btnA {
              &:first-child{
                min-height: 42px;
              }
            }
          }
        }
      }
    }
    &__bg {
      @include media(sm) {
        position: absolute;
        right: 0;
        top: 617px; // top: 576px;
        .jp &{
          top: 637px;
        }
        img {
          width: 195px;
        }
      }
    }
    &__scrollIcon {
      margin-top: 9rem; // margin-top: 8rem;
      display: flex;
      justify-content: center;
      .jp &{
        margin-top: 9rem;
      }
      @include media(sm) {
        margin-top: 10px;
        justify-content: flex-start;
        .jp &{
          margin-top: 10px;
        }
      }
      img {
        @include media(sm) {
          width: 28px;
        }
      }
    }
  }
  .p-sec01 {
    padding-bottom: 2rem;
    @include media(sm) {
      padding-bottom: 4rem;
    }
    .c-titA {
      &__bg {
        @include media(sm) {
          left: 170px;
          right: auto;
        }
      }
    }
    &-box {
      display: flex;
      justify-content: space-between;
      @include media(sm) {
        flex-direction: column;
      }
      &__textArea {
        margin-right: 80px;
        @include media(sm) {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
      &__imageArea {
        flex-shrink: 0;
      }
    }
  }
  .p-sec02 {
    background: linear-gradient(to right, #3dbbe4, #3699e9);
    padding: 6rem 0 8rem;
    @include media(sm) {
      padding: 5rem 0 6.5rem;
    }
    &__tit {
      @include fz(28, 33.6, 400);
      color: #fff;
      margin-bottom: 2.5rem;
      .jp &{
        font-weight: 700;
        span{
          font-size: 32px;
          @include media(sm) {
            font-size: 24px;
          }
        }
      }
      @include media(sm) {
        @include fz(20, 30, 400);
        margin-bottom: 1.5rem;
      }
    }
    &-box {
      display: flex;
      @include media(sm) {
        flex-direction: column;
      }
      &__textArea {
        margin-right: 50px;
        @include media(sm) {
          margin-right: 0;
          margin-bottom: 30px;
        }
        .btnArea{
          margin-top: 3rem;
          .c-btnC{
            &.btn02{
              &::before{
                content: "";
                display: inline-block;
                background-image: url("/assets/images/top-video-play_btn.svg");
                background-size: contain;
                background-repeat: no-repeat;
                width: 26px;
                height: 26px;
                margin-right: 5px;
              }
              &:hover::before{
                background-image: url("/assets/images/top-video-play_btn_wh.svg");
              }
            }
          }
        }
      }
      &__imageArea {
        .videoArea {
          @include media(sm) {
            position: absolute;
            width: 100%;
            top: 0;
          }
          .videoInner {
            position: relative;
            width: 550px;
            @include media(sm) {
              width: 100%;
              padding-top: 0;
            }
            img{
              &.thumbnail{
                position: relative;
              }
              &.btn{
                width: 80px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                opacity: 0.8;
              }
            }
          }
        }
        @include media(sm) {
          position: relative;
          padding-top: 58.18%;
        }
        iframe {
          width: 550px;
          height: 320px;
          @include media(sm) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &-popup{
      position: fixed;
      display: block;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      opacity: 0;
      z-index: -1;
      &.on{
        opacity: 1;
        z-index: 100;
      }
      &-vertical-middle{
        @include media(sm) {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
          overflow: scroll;
          .popup &{
            display: block;
            height: 70vh;
          }
        }
      }
      &-block{
        text-align: -webkit-center;
        height: 100vh;
        overflow: scroll;
        &-item{
          position: relative;
          padding-top: 39.3%;
          width: 70vw;
          top: 50%;
          transform: translateY(-50%);
          @include media(sm) {
            width: 90%;
            padding-top: 50.52%;
          }
          iframe{
            width: 100% !important;
            height: 100% !important;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
      &-background{
        display: none;
        &.on{
          display: block;
          position: fixed;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.6);
          top: 0;
          left: 0;
          z-index: -1;
        }
      }
    }
  }
  .p-sec03 {
    position: relative;
    background-color: #e7f3fc;
    padding: 8rem 0;
    @include media(sm) {
      padding: 6.5rem 0;
    }
    &__bg {
      position: absolute;
      &--01 {
        top: 100px;
        right: 0;
        @include media(sm) {
          display: none;
        }
      }
      &--02 {
        right: 0;
        bottom: -150px;
        @include media(sm) {
          bottom: -81px;
        }
        img {
          @include media(sm) {
            width: 195px;
          }
        }
      }
    }
    .c-titA {
      &__titWrap{
        position: relative;
      }
      &__bg {
        position: absolute;
        right: 0;
        @include media(sm) {
          top: -30px;
          right: auto;
          left: 50px;
        }
      }
      &__jp{
        display: block;
        margin-top: 3rem;
        @include media(sm) {
          margin-top: 1rem;
        }
      }
    }
    &-box {
      display: flex;
      justify-content: space-between;
      @include media(sm) {
        flex-direction: column;
      }
      &__textArea {
        margin-right: 58px;
        @include media(sm) {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
      &__imageArea {
        flex-shrink: 0;
        margin-top: -20px;
        @include media(sm) {
          margin-top: 0;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    &-imageContents {
      text-align: center;
      margin-top: -5rem;
      @include media(sm) {
        margin-top: unset;
        position: relative;
        picture{
          position: absolute;
          top: -6%;
          left: 50%;
          width: 100%;
          max-width: 360px;
          transform: translateX(-50%);
          padding: 0 13px;
        }
      }
      &__text{
        padding-top: 2rem;
         @include media(sm) {
          padding-top: 40vw;
          max-width: 370px;
          margin: 0 auto;
          .jp &{
            padding-top: 41vw;
          }
          @media screen and (min-width: 400px) {
            padding-top: 161px;
            .jp &{
              padding-top: 165px;
            }
          }
        }
        ul{
          display: flex;
          justify-content: center;
          gap: 4.8rem;
          @include media(sm) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40vw 0;
            .jp &{
              gap: 41vw 0;
            }
            @media screen and (min-width: 400px) {
              gap: 162px 0;
              .jp &{
                gap: 167px 0rem;
              }
            }
          }
          li{
            width: 22%;
            font-size: 18px;
            line-height: 1.4;
            .jp &{
              font-weight: 700;
            }
            @include media(sm) {
              width: unset;
              font-size: 12px; // font-size: 14px;
              .jp &{
                font-size: 14px;
                line-height: 1.6;
              }
              &:nth-child(1){
                padding-right: 2.5rem;
              }
              &:nth-child(2){
                padding-left: 2.5rem;
              }
              &:nth-child(3){
                padding-right: 1rem;
              }
              &:nth-child(4){
                padding-left: 1rem;
                margin-right: -9px;
                letter-spacing: -0.07px;
              }
            }
          }
        }
      }
    }
    &__btnArea {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-top: 78px;
      position: relative;
      z-index: 0;
      @include media(sm) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
        margin-top: 50px;
      }
    }
  }
  .p-sec04 {
    position: relative;
    padding: 5rem 0 8rem;
    @include media(sm) {
      padding: 6.5rem 0;
    }
    &__bg {
      position: absolute;
      bottom: -174px;
      left: 0;
      z-index: 1;
      @include media(sm) {
        bottom: -96px;
      }
      img {
        @include media(sm) {
          width: 175px;
        }
      }
    }
    .c-titA {
      &__bg {
        @include media(sm) {
          right: auto;
          left: 190px;
          .jp &{
            left: 105px;
          }
        }
      }
    }
    &__slideArea {
      position: relative;
      @include media(sm) {
        padding: 0 20px;
      }
      .swiper {
        padding-bottom: 117px;
        // padding-left: calc((100vw - 1100px) / 2);
        @include media(sm) {
          padding-bottom: 50px;
          padding-left: 0;
        }
        &-slide{
          @include media(lg) {
            width: 353px !important;
          }
          .c-cardA{
            @include media(sm) {
              height: 370px;
            }
          }
        }
        &-pagination {
          bottom: 40px;
          @include media(sm) {
            bottom: 12px;
          }
          &-bullet {
            width: 20px;
            height: 20px;
            background-color: #dbedfb;
            opacity: 1;
            @include media(sm) {
              width: 16px;
              height: 16px;
            }
            &-active {
              background-color: #0e84e5;
            }
          }
        }
        &-button-prev,
        &-button-next {
          border: 1px solid #0e84e5;
          background-color: #fff;
          border-radius: 50%;
          width: 55px;
          height: 55px;
          top: 40%;
          @include media(sm) {
            top: auto;
            bottom: 3px;
            width: 35px;
            height: 35px;
          }
          &::after {
            font-size: 22px;
            @include media(sm) {
              font-size: 15px;
            }
          }
        }
        &-button-prev {
          left: calc((100vw - 1280px) / 2);
          @include media(sm) {
            left: 38px;
          }
        }
        &-button-next {
          right: calc((100vw - 1280px) / 2);
          @include media(sm) {
            right: 38px;
          }
        }
      }
    }
    &-logoArea {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 7rem 10%;
      margin: 5rem auto 0;
      width: 1100px;
      &__item{
        img{
          width: auto;
          height: 40px;
          &.size-l{
            height: 55px;
          }
          &.size-m{
            height: 35px;
          }
          &.size-s{
            height: 30px;
          }
        }
      }
      @include media(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0 3rem;
        column-gap: 3rem;
        row-gap: 4rem;
        margin: 3.5rem 0 0;
        width: unset;
        &__item{
          text-align: center;
        }
      }
    }
    &__btnArea {
      position: relative;
      z-index: 2;
      margin-top: 78px;
      display: flex;
      justify-content: center;
      @include media(sm) {
        margin-top: 50px;
      }
    }
  }
  .p-sec05 {
    position: relative;
    background-color: #e7f3fc;
    padding: 6rem 0;
    @include media(sm) {
      padding: 6.5rem 0;
    }
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .c-inner{
      position: relative;
      z-index: 1;
    }
    .c-titA {
      &__bg {
        @include media(sm) {
          right: auto;
          left: 100px;
        }
      }
    }
    &-box {
      display: flex;
      justify-content: center;
      @include media(sm) {
        flex-direction: column;
      }
      &__textArea {
        margin-right: 50px;
        @include media(sm) {
          margin-right: 0;
          margin-bottom: 40px;
          display: contents;
        }
        .btnArea {
          margin-top: 78px;
          display: flex;
          align-items: center;
          gap: 30px;
          @include media(sm) {
            flex-direction: column;
            order: 2;
            margin-top: 50px;
            gap: 15px;
          }
          .c-btnC {
            &:first-child{
              &::after{
                content: "";
                display: inline-block;
                background-image: url("/assets/images/icon_link.svg");
                background-size: contain;
                background-repeat: no-repeat;
                width: 14px;
                height: 14px;
                margin-left: 6px;
                margin-top: 2px;
              }
              &:hover::after{
                background-image: url("/assets/images/icon_link-wh.svg");
              }
            }
          }
        }
      }
      &__imageArea {
        flex-shrink: 0;
        @include media(sm) {
          margin-top: 30px;
        }
      }
    }
  }
  .p-sec06 {
    padding: 6rem 0 8rem;
    @include media(sm) {
      padding: 6.5rem 0 4rem;
    }
    &__tit {
      text-align: center;
      @include fz(40, 40, 400);
      color: $tit;
      margin-bottom: 66px;
      .jp &{
        font-weight: 700;
      }
      @include media(sm) {
        @include fz(30, 30, 400);
        margin-bottom: 40px;
      }
    }
    &-list {
      padding: 0 10rem;
      @include media(sm) {
        padding: 0;
      }
      &__item {
        + .p-sec06-list__item {
          margin-top: 45px;
          @include media(sm) {
            margin-top: 35px;
          }
        }
        .itemLink {
          display: flex;
          @include media(sm) {
            display: block;
          }
          .info {
            display: flex;
            align-items: center;
            @include media(sm) {
              justify-content: space-between;
            }
            .date {
              @include fz(17, 30.6, 400);
              padding-bottom: 2px;
              margin-right: 2rem;
              width: 85px;
              .jp &{
                font-size: 16px;
                font-weight: 700;
              }
              @include media(sm) {
                margin-right: 10px;
                width: unset;
                font-size: 15px;
                .jp &{
                  font-size: 14px;
                }
              }
            }
            .cat {
              color: #fff;
              @include fz(14, 18, 400);
              width: 140px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px 10px 6px;
              border-radius: 50vw;
              background-color: $gray;
              .jp &{
                font-weight: 700;
              }
              &--company {
                background-color: #0E84E5;
              }
              &--feature {
                background-color: #1169B2;
              }
              &--webinar {
                background-color: #004E98;
              }
            }
          }
          .tit {
            @include fz(17, 30.6, 400);
            margin-left: 3rem;
            .jp &{
              font-size: 16px;
              font-weight: 700;
            }
            @include media(sm) {
              margin-left: 0;
              margin-top: 10px;
              font-size: 15px;
              .jp &{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    &__btnArea {
      margin-top: 78px;
      display: flex;
      justify-content: center;
      @include media(sm) {
        margin-top: 50px;
      }
    }
  }
}
