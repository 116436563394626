@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

.l-main {
  margin-top: 80px;
  overflow: hidden;
  @include media(sm) {
    margin-top: 65px;
  }
}
.p-faq {
  padding: 6rem 0;
  background-color: #FBFBFB;
  @include media(sm) {
    padding: 4rem 0;
  }
  &-contents {
    margin-top: 5rem;
    .en &{
      margin-top: 2rem;
    }
    @include media(sm) {
      margin-top: 3rem;
      .en &{
        margin-top: 1rem;
      }
    }
    ul {
      li {
        position: relative;
        background-color: #E7EEF4;
        border-radius: 20px;
        padding: 24px 140px 24px 125px;
        @include media(sm) {
          padding: 18px 20px;
          border-radius: 15px;
        }
        + li {
          margin-top: 2rem;
          @include media(sm) {
            margin-top: 1.5rem;
          }
        }
        &::after {
          position: absolute;
          top: -2px;
          left: -2px;
          z-index: -1;
          border-radius: 10px;
          content: '';
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          background: linear-gradient(to right, #88aecd, #cae29e);
        }
        .question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:hover {
            opacity: 1;
          }
          .title {
            @include fz(18, 25.2, 700);
            color: $dblue;
            text-indent: -1.5em;
            padding-left: 1.5em;
            @include media(sm) {
              font-size: 16px;
              .jp &{
                font-size: 15px;
              }
            }
            .en {
              @include fz(20, 20, 700);
              color: $tit;
              margin-right: 2px;
              @include media(sm) {
                font-size: 18px;
                transform: translateY(-4px);
              }
            }
          }
          .icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            right: -35px;
            @include media(sm) {
              right: -10px;
            }
            span {
              background-color: #00759d;
              width: 18px;
              height: 2px;
              border-radius: 2px;
              &:nth-child(2) {
                position: absolute;
                transform: rotate(270deg);
                transition: 0.4s ease;
              }
            }
          }
          &.is-open {
            .icon {
              span {
                transform: rotate(405deg);
                &:nth-child(2) {
                  transform: rotate(135deg);
                }
              }
            }
          }
        }
        .answer {
          padding-top: 30px;
          display: none;
          @include media(sm) {
            padding-top: 20px;
          }
          .flex {
            display: flex;
            .text {
              @include fz(16, 24, 400);
              color: $gray;
              .jp &{
                font-size: 15px;
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 14px;
                .jp &{
                  font-size: 13px;
                }
              }
              a{
                color: $tit;
                text-decoration: underline;
                text-underline-offset: 2px;
              }
            }
          }
        }
      }
    }
  }
  &__btnArea {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    @include media(sm) {
      margin-top: 4rem;
    }
  }
}