@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#cases {
  .p-sec01 {
    padding: 3rem 0 4rem; // 選択タブ表示対応時：padding: 5rem 0 4rem;
    @include media(sm) {
      padding: 0 0 1rem; // 選択タブ表示対応時：padding: 4rem 0 1rem;
    }
    .isotope-list {
      .upper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        @include media(sm) {
          // flex-direction: column;
          // gap:8px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px 5px;
        }
        a {
          position: relative;
          background-color: #cfe6fa;
          width: 170px;
          min-height: 45px;
          border-radius: 50vw;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 7px 5px rgba($color: #cddcea, $alpha: 0.5);
          @include media(sm) {
            width: 100%;
            min-height: 35px;
            box-shadow: 0 3px 5px rgba($color: #cddcea, $alpha: 0.5);
          }
          &::after {
            opacity: 0;
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 17px 10px 0 10px;
            border-color: #0e84e5 transparent transparent transparent;
            @include media(sm) {
              display: none;
            }
          }
          &.is-active {
            background-color: #0e84e5;
            color: #fff;
            &::after {
              opacity: 1;
            }
          }
          &:first-child {
            &::after {
              display: none;
            }
          }
          span {
            @include fz(20, 20, 400);
            .jp &{
              font-size: 16px;
              font-weight: 700;
            }
            @include media(sm) {
              font-size: 12px;
              .jp &{
                font-size: 12px;
              }
            }
          }
        }
      }
      .lower {
        background-color: #0e84e5;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 30px;
        margin-bottom: 2rem;
        padding: 30px;
        @include media(sm) {
          display: none;
          width: 100%;
          margin: 9px auto 0;
          padding: 15px;
          gap: 12px;
        }
        &--no {
          background-color: transparent;
          height: 1px;
          margin: 0;
        }
        li {
          border: 1px solid #fff;
          border-radius: 50vw;
          padding: 5px 26px 6px;
          cursor: pointer;
          @include media(sm) {
            display: block;
            text-align: center;
            padding: 4px 12px 6px;
          }
          &.iso-active {
            background-color: #fff;
            span {
              color: #0e84e5;
              .jp &{
                font-weight: 700;
              }
            }
          }
          span {
            @include fz(16,21,400);
            color: #fff;
            .jp &{
              font-size: 15px;
              font-weight: 700;
            }
            @include media(sm) {
              font-size: 12px;
              .jp &{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .isotope-item {
      display: flex;
      gap: 20px;
      margin-top: 2rem;
      @include media(sm) {
        margin-top: 4rem;
        grid-template-columns: repeat(1, 1fr);
      }
      .c-cardA {
        margin-bottom: 20px;
      }
    }
  }
}

//isotopeの幅調整
.grid-sizer{
  display: flex;
  width: 350px;
  @include media(tab) {
    width: 31.34%;
  }
  @include media(sm) {
    width: 100%;
  }
}
.gutter-sizer{
  gap: 20px;
  @include media(tab) {
    gap: 3%;
  }
}
