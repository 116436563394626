@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

#news {
  &.list {
    .p-news {
      padding: 5rem 0 6rem;
      @include media(sm) {
        padding: 4rem 0;
      }
      &-tab {
        padding: 0 10rem 8rem;
        @include media(sm) {
          padding: 0 0 4rem;
        }
        .filter-list {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
          @include media(sm) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px 5px;
          }
          li {
            padding: 12.25px 15px 10.25px;
            min-width: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #CFE6FA;
            border-radius: 50vw;
            box-shadow: 0 7px 5px rgba($color: #CDDCEA, $alpha: .5);
            @include media(sm) {
              padding: 4px 0 6px;
              min-width: unset;
              min-height: 35px;
              box-shadow: 0 3px 5px rgba($color: #CDDCEA, $alpha: .5);
            }
            span {
              @include fz(16, 22.4, 400);
              color: #231815;
              .jp &{
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 12px;
              }
            }
            &.is-active {
              background-color: #0E84E5;
              border-radius: 50vw;
              span {
                color: #fff;
              }
            }
          }
        }
      }
      &-list {
        padding: 0 10rem;
        @include media(sm) {
          padding: 0;
        }
        &__item {
          + .p-news-list__item {
            margin-top: 45px;
            @include media(sm) {
              margin-top: 35px;
            }
          }
          .listLink {
            position: relative;
            display: flex;
            @include media(sm) {
              display: block;
            }
            .infoArea {
              display: flex;
              align-items: center;
              margin-right: 3rem;
              @include media(sm) {
                margin-right: 0;
                margin-bottom: 5px;
                justify-content: space-between;
              }
              .date {
                color: #000000;
                @include fz(17, 30.6, 400);
                margin-right: 2rem;
                padding-bottom: 2px;
                width: 85px;
                .jp &{
                  font-size: 16px;
                  font-weight: 700;
                }
                @include media(sm) {
                  margin-right: 10px;
                  font-size: 15px;
                  .jp &{
                    font-size: 14px;
                  }
                }
              }
              .cat {
                color: #fff;
                @include fz(14,18,400);
                width: 140px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50vw;
                background-color: $gray;
                padding: 4px 10px 6px;
                .jp &{
                  font-weight: 700;
                }
                &--company {
                  background-color: #0E84E5;
                }
                &--feature {
                  background-color: #1169B2;
                }
                &--webinar {
                  background-color: #004E98;
                }
              }
            }
            .title {
              @include fz(17, 30.6, 400);
              color: #000000;
              .jp &{
                font-size: 16px;
                font-weight: 700;
              }
              @include media(sm) {
                font-size: 15px;
                .jp &{
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      &__pager {
        margin-top: 6rem;
        display: flex;
        justify-content: center;
        @include media(sm) {
          margin-top: 50px;
        }
      }
    }
  }
  &.detail {
    .p-sec01 {
      padding-top: 5rem;
      @include media(sm) {
        padding-top: 4rem;
      }
      .c-titA {
        text-align: center;
        &__main {
          @include fz(36, 45, 400);
          color: $tit;
          .jp &{
            font-weight: 700;
          }
          @include media(sm) {
            font-size: 25px;
          }
        }
      }
      .date{
        margin-top: 4rem;
        @include fz(18, 21.6, 400);
        letter-spacing: 0.02em;
        text-align: right;
        .jp &{
          font-weight: 700;
        }
        @include media(sm){
          font-size: 14px;
          margin-top: 20px;
        }
      }
      &-contents {
        margin-top: 3rem;
        @include media(sm){
          margin-top: 20px;
        }
      }
    }
    .p-sec02 {
      padding-bottom: 8rem;
      @include media(sm) {
        padding-bottom: 5rem;
      }
      &__btnArea {
        margin-top: 78px;
        display: flex;
        justify-content: center;
        @include media(sm) {
          margin-top: 50px;
        }
      }
      &__sns {
        margin-top: 69px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media(sm) {
          margin-top: 50px;
        }
        .snsItem {
          +.snsItem {
            margin-left: 7px;
          }
        }
      }
    }
  }
}