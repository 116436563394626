@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

.l-hdr {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  padding: 21.7px 40px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  @include media(pc) {
    padding: 25px 3%;
    @include media(sm) {
      padding: 15px 20px 15px 24px
    }
  }
  &.is-active {
    @include media(sm) {
      position: fixed;
      z-index: 98;
    }
  }
  &-inside{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1440px;
  }
  &-logo{
    display: flex;
    align-items: center;
    margin-right: 70px;
    @media screen and (max-width: 1599px) {
      margin-right: 10px;
      @include media(pc) {
        margin-right: 40px;
        @media screen and (max-width: 1199px) {
          margin-right: 2vw;
          @include media(sm) {
            margin-right: 20px;
            align-items: center;
            position: relative;
            z-index: 999;
          }
        }
      }
    }
    &__logo {
      width: 85px;
      padding-top: 5px;
      flex-shrink: 0;
      @media screen and (max-width: 1199px) {
        width: 9vw;
        @include media(sm) {
          width: 68px;
        }
      }
    }
  }
  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__menuWrap {
      display: flex;
      align-items: center;
    }
    &__nav {
      display: flex;
      align-items: center;
      .navItem {
        @include fz(16, 19, 400);
        color: #000000;
        white-space: nowrap;
        @include media(pc) {
          @include fz(14, 16.8, 400);
        }
        .icon{
          vertical-align: baseline;
          @include media(pc) {
            margin-bottom: -1px;
            .en &,.th &{
              width: 12px;
              height: 12px;
            }
          }
        }
        .jp &{
          font-weight: 700;
          span{
            font-size: 18px;
            @include media(pc) {
              font-size: 16px;
            }
          }
        }
        +.navItem {
          // margin-left: 50px;
          // @media screen and (max-width: 1599px) {
            margin-left: 20px;
            .jp &{
              margin-left: 22px;
              &:nth-child(n+5){
                margin-left: 20px;
              }
              &:nth-child(2),
              &:last-child{
                margin-left: 18px;
              }
            }
            @include media(pc) {
              margin-left: 20px;
            }
          }
        // }
      }
      @include media(sm) {
        display: none;
      }
    }
    &__btn {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-left: 40px;
      @include media(pc) {
        gap: 1vw;
      }
      @include media(sm) {
        display: none;
      }
      a{
        &:hover{
          opacity: 1;
          .loginText{
            background-color: #3699E9;
            color: #fff;
            border: none;
            border: 2px solid #3699E9;
          }
        }
      }
      .login {
        .loginText {
          border: 2px solid #000;
          border-radius: 50vw;
          padding: 4px 15px 8px;
          @include fz(16, 19, 400);
          color: #000;
          display: block;
          white-space: nowrap;
          @include media(pc) {
            @include fz(14, 16.8, 400);
            padding: 6.1px 15px 10.1px;
          }
          .jp &{
            font-weight: 700;
            margin-bottom: 1px;
            padding: 5.5px 15px;
            @include media(pc) {
              padding: 8.1px 15px;
            }
          }
        }
      }
      .c-btnB{
        padding-bottom: 4px;
        @include media(pc) {
          min-height: 35px;
        }
      }
      .c-btnA {
        min-height: 35px;
        padding: 0 15px 2px;
        @include media(pc) {
          @include fz(14, 16.8, 400);
          width: unset !important;
          padding: 0 15px 2px;
          white-space: nowrap;
          min-height: 35px;
          .jp &{
            font-weight: 700;
          }
        }
        &:nth-child(2) {
          width: 180px;
        }
        &:nth-child(3) {
          width: 145px;
        }
      }
    }
    &__ham {
      @include media(sm) {
        position: relative;
        z-index: 999;
        margin-left: 12px;
        width: 35px;
        height: 35px;
        background: linear-gradient(to right, #fff, #A7D6FF);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .hamInner {
          width: 32px;
          height: 32px;
        }
        span {
          position: absolute;
          background-color: #02254f;
          width: 11px;
          height: 1.7px;
          display: block;
          left: 10px;
          transition: .4s all;
          &.first {
            top: 10px;
          }
          &.second {
            top: 16px;
            width: 17px;
            margin-left: 1px;
            transform: translateY(1px);
          }
          &.third {
            bottom: 10px;
          }
        }
        &.is-active {
          .hamInner {
            background-color: #e1ecf3;
            border-radius: 50%;
          }
          span {
            left: 10px;
            width: 17px;
            &.first {
              transform: rotate(-45deg);
              transform-origin: center;
              top: 17px;
            }
            &.second {
              opacity: 0;
            }
            &.third {
              bottom: 12px;
              transform: rotate(45deg);
              transform-origin: center;
              bottom: 17px;
            }
          }
        }
      }
    }
    &__lang {
      margin-left: 15px;
      @include media(sm) {
        margin-left: 14px;
        z-index: 100;
      }
      .langWrap {
        position: relative;
        cursor: pointer;
      }
      .langSelect {
        position: relative;
        display: flex;
        align-items: center;
        .icon {
          width: 17px;
          height: 17px;
          margin-right: 9px;
          margin-top: 2px;
          .jp &{
            margin-right: 10px;
          }
        }
        .current{
          display: block;
          color: $dblue;
          .jp &{
            font-weight: 700;
          }
        }
        .arrow {
          position: absolute;
          right: 0;
          pointer-events: none;
          margin-top: 5px;
        }
      }
      .secondList {
        display: none;
        position: absolute;
        top: 19px;
        left: 6px;
        animation: langFade 0.7s 0.08s both;
        &.is-active{
          display: block;
        }
        @keyframes langFade{
          0%{
              opacity: 0;
          }
          100%{
              opacity: 1;
          }
        }
        .jp &{
          font-weight: 700;
          top: 34px;
          left: 3px;
        }
        li {
          +li {
            margin-top: 15px;
          }
        }
        a{
          padding: 3px 20px;
        }
      }
    }
    .spMenuWrap {
      display: none;
    }
    &__spMenu {
      @include media(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        z-index: 99;
        overflow-y: scroll;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        background-size: auto;
        width: 100%;
        height: 100vh;
        .spNavWrap{
          position: absolute;
          top: 46%;
          transform: translateY(-50%);
        }
        .spNav {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .navItem {
            @include fz(20, 24, 400);
            color: $dblue;
            .jp &{
              font-size: 18px;
              font-weight: 700;
            }
            .icon{
              vertical-align: baseline;
            }
            +.navItem {
              margin-top: 20px;
            }
          }
        }
        .spBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 34px;
          .login {
            width: 169px;
            min-height: 33px;
            text-align: center;
            margin-bottom: 18px;
            //.jp &{
            //  width: 200px;
            //  height: 40px;
            //}
            .loginText {
              border: 2px solid #000;
              border-radius: 50vw;
              padding: 4px 15px 8px;
              @include fz(14, 16.8, 400);
              color: $dblue;
              display: block;
              .jp &{
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 1px;
                padding: 4px 15px;
              }
            }
          }
          .c-btnA {
            width: 169px;
            //.jp &{
            //  width: 200px;
            //  height: 40px;
            //}
          }
          .c-btnB {
            .jp &{
              width: 169px;
              min-height: 33px;
            }
          }
        }
      }
    }
  }
}