@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-text16 {
  @include fz(17, 34, 400);
  .jp &{
    font-size: 16px;
    font-weight: 700;
    font-feature-settings: "palt";
    letter-spacing: 0.9px;
  }
  @include media(sm) {
    font-size: 15px;
    line-height: 1.5;
    .jp &{
      font-size: 14px;
    }
  }
  &--white {
    color: #fff;
  }
}