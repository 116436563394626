@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#faq {
  .p-anchor {
    padding-top: 5rem;
    @include media(sm) {
      padding-top: 4rem;
    }
    &__nav {
      display: flex;
      justify-content: center;
      align-items: center;
      @include media(sm) {
       display: grid;
       grid-template-columns: repeat(2,1fr);
       column-gap: 5px;
       row-gap: 20px;
      }
      .link {
        position: relative;
        display: inline-block;
        color: $tit;
        @include fz(16, 22, 700);
        @include media(sm) {
          text-align: center;
          .jp &{
            font-size: 14px;
            letter-spacing: -0.25px;
          }
        }
        &::after {
          transition: .3s;
        }
        &:hover {
          &::after {
            position: absolute;
            content: "";
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 2px;
            background-color: $tit;
          }
        }
        + .link {
          margin-left: 6rem;
          @include media(sm) {
            margin-left: 0;
          }
        }
      }
    }
  }
  .p-faq {
    padding-top: 0;
    background-color: #fff;
    &-contents {
      margin-top: 5rem;
      @include media(sm) {
        margin-top: 4rem;
      }
      + .p-faq-contents {
        margin-top: 8rem;
        @include media(sm) {
          margin-top: 4rem;
        }
      }
      &__tit {
        @include fz(28, 39.2, 400);
        text-align: center;
        color: $tit; 
        margin-bottom: 4rem;
        .jp &{
          font-weight: 700;
        }
        @include media(sm) {
          font-size: 20px;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
