@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-cardA {
  padding: 23px;
  border: 1px solid #0E84E5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 353px;
  height: 438px;
  @include media(sm) {
    align-items: flex-start;
    padding: 20px;
    width: unset;
    height: unset;
  }
  &__imageArea {
    margin-bottom: 25px;
    width: 305px;
    height: 186px;
    overflow: hidden;
    border-radius: 5px;
    @include media(sm) {
      width: 100%;
      height: auto;
      margin-bottom: 18px;
    }
    .en &{
      max-height: 155px;
      height: unset;
      @include media(sm) {
        aspect-ratio: 293 / 153;
        height: auto;
      }
    }
  }
  &__textArea {
    .catArea {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      .cat {
        @include fz(14,20,400);
        background-color: #0e84e5;
        color: #fff;
        border-radius: 30px;
        padding: 2px 22px 4px;
        .jp &{
          font-weight: 700;
          padding: 2px 17px 4px;
          @include media(sm) {
            padding: 2px 14px 4px;
          }
        }
      }
    }
    .storyArea {
      margin-top: 22px;
      @include media(sm) {
        margin-top: 15px;
      }
      .company {
        @include fz(16,20,700);
      }
      .text {
        @include fz(20,30,400);
        margin-top: 20px;
        .jp &{
          font-weight: 700;
        }
        @include media(sm) {
          font-size: 17px;
          margin-top: 12px;
        }
      }
    }
  }
}