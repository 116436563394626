@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-pankuzu {
  padding: 12px 0;
  @include media(sm) {
    display: none;
  }
  ul {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    li {
      display: inline;
      + li {
        &:before {
          margin: 0 3px;
          color: black;
          content: ">";
        }
      }
      a,
      span {
        font-size: 16px;
      }
      a {
        text-decoration: underline;
      }
      span {
        margin-left: 4px;
      }
    }
  }
  //WPプラグイン用
  &__list {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    > span {
      + span {
        &:before {
          margin: 0 3px;
          color: black;
          font-size: 14px;
          content: ">";
          @include media(sm) {
            font-size: 12px;
          }
        }
      }
      > a,
      > span {
        font-size: 14px;
        @include media(sm) {
          font-size: 12px;
        }
      }
    }
  }
}
